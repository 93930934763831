import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { GoInfo } from "react-icons/go";
import { IoChevronBack, IoChevronDown } from "react-icons/io5";
import { TfiPrinter } from "react-icons/tfi";
import { Link, useLocation } from "react-router-dom";
import { queryClient } from "../../../../config/useQuery.config";
import Breadcrumb from "../../hr-setup_modules/components/Breadcrumb";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import ReactSelect from "../../components/reactSelect/ReactSelect";
import ClassRosterTable from "../../components/tables/ClassRosterTable";
import SummerRosterTable from "../../components/tables/SummerRosterTable";
import SummerPrintPreviewModal from "../../enrollment_Tracking/components/modals/SummerPrintPreviewModal";
import { StudentStatuses } from "../../enums/Foray.enums";
import { StudentQueryKeyString } from "../../enums/studentQueryKey.enum";
import { useCenterSetup } from "../../hooks/useCenterSetup";
import { useClassrooms } from "../../hooks/useClassrooms";
import { useStudentsOfClass } from "../../hooks/useStudents";
import { forayRoutes } from "../../routes/routes";
import { studentSvc } from "../../services/student.service";
import { IOptionS } from "../../types/ICenterSetup.interface";
import { IClassroomSetup } from "../../types/IClassroomSetup.interface";
import { StudentInfoDetailed } from "../../types/IStudentSetup.interface";

const ClassRoster = () => {
  const [classroomOptions, setClassroomOptions] = useState<IOptionS[]>([]);
  const [selectedClassroom, setSelectedClassroom] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const [categorizedStudents, setCategorizedStudents] =
    useState<Record<string, StudentInfoDetailed[]>>();
  const [activeAccordion, setActiveAccordion] = useState<string | null>(
    StudentStatuses.Enrolled
  ); // Default open status
  const [summerWeeks, setSummerWeeks] = useState<string[]>([])

  const location = useLocation();
  const { data: center } = useCenterSetup();
  const { data: classrooms } = useClassrooms();
  const { data: studentsOfClassroom } = useStudentsOfClass(
    false,
    selectedClassroom
  );

  const handleGetStudentsInfo = async () => {

    const res = await studentSvc.fetchStudentsInfo();
    if (res?.ok) {
      setSummerWeeks(res?.data?.weeks)
    }
  }

  const handleSelectClassroom = (selectedValue: string) => {
    setSelectedClassroom(selectedValue);
  };

  const handleUpdateStudent = async (
    student: StudentInfoDetailed,
    comment: string
  ) => {
    const reqBody: any = { ...student };
    reqBody.comment = comment;
    const resp = await studentSvc.updateStudentSetup(
      student?._id as string,
      reqBody,
      undefined
    );
    if (resp?.ok) {
      queryClient.invalidateQueries({
        queryKey: [StudentQueryKeyString.StudentsOfClass],
      });
    }
  };

  const handleGetClassroom = (classroomId: string) => {
    const tempClassroom = classrooms?.classrooms?.find(
      (classroom: IClassroomSetup) => classroom?._id === classroomId
    );
    return tempClassroom;
  };

  const toggleAccordion = (status: string) => {
    setActiveAccordion((prev) => (prev === status ? null : status)); // Toggle on click
  };

  useEffect(() => {
    const temp = classrooms?.classrooms?.map((classroom: IClassroomSetup) => ({
      value: classroom?._id,
      label: classroom?.classroomName,
    }));
    setClassroomOptions(temp as IOptionS[]);
  }, [classrooms]);

  useEffect(() => {
    setSelectedClassroom(location.state?.classroomId || " ");
    handleGetStudentsInfo()
  }, [location]);

  useEffect(() => {
    if (studentsOfClassroom?.data?.students) {
      const categorized = studentsOfClassroom.data?.students?.reduce(
        (
          acc: Record<string, StudentInfoDetailed[]>,
          student: StudentInfoDetailed
        ) => {
          const status = student.status || "Unknown";
          if (!acc[status]) {
            acc[status] = [];
          }
          acc[status].push(student);
          return acc;
        },
        {} as Record<string, StudentInfoDetailed[]>
      );

      setCategorizedStudents(categorized);
    }
  }, [studentsOfClassroom]);

  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_dashboard },
    {
      label: "Enrollment Tracking & Optimization",
      link: forayRoutes.enrollment_tracking,
    },
    { label: "Class Roster", isCurrent: true },
  ];

  return (
    <>
      <DashboardWrapper>
        <div className="bg-secondaryNeutral py-6 ps-6 overflow-y-scroll h-[calc(100vh-84px)] hide-scrollbar">
          <div className="space-y-8">
            <div className="flex items-center justify-between">
              <Breadcrumb items={breadcrumbItems} />
              <Link
                to={forayRoutes.enrollment_tracking}
                className="flex items-center gap-2 bg-white rounded-md px-3 py-2 text-lg text-secondaryVariant font-semibold max-w-max me-6"
              >
                <IoChevronBack /> Back to Enrollment Tracking
              </Link>
            </div>

            <div className="flex items-center justify-between">
              <h2 className="text-2xl text-primary font-semibold">
                Class Roster
              </h2>
              <div className="w-[244px] pe-6">
                <ReactSelect
                  label={""}
                  placeholder={"Select classroom"}
                  options={classroomOptions}
                  value={classroomOptions?.find(
                    (classroom) => classroom?.value === selectedClassroom
                  )}
                  onChange={(selectedValue) => {
                    handleSelectClassroom(selectedValue?.value as string);
                  }}
                />
              </div>
            </div>
          </div>

          {/* ACCORDION SECTION */}
          <Accordion activeKey={activeAccordion} alwaysOpen={false}>
            {categorizedStudents &&
              Object.entries(categorizedStudents)?.map(([status, students]) => (
                <div key={status}>
                  <Accordion.Item eventKey={status} >
                    {/* Accordion Header (Clickable) */}
                    <div className="w-full bg-primaryVariant rounded-lg mb-2 mt-8 px-8 py-3 flex justify-between items-center">
                      <h3 className="text-xl text-secondary font-semibold flex items-center gap-2 uppercase w-full">
                        {status} <GoInfo className="w-5 h-5 opacity-50" />
                      </h3>
                      {/* Rotating Arrow */}
                      <div className="flex items-center gap-2">
                        {/* add this print button just in summer roster details   */}
                        {/* <button onClick={openModal}>
                          <TfiPrinter className="w-6 h-6 text-secondary" />
                        </button> */}
                        <button onClick={() => toggleAccordion(status)}>
                          <IoChevronDown
                            className={`w-6 h-6 transition-transform ${activeAccordion === status
                              ? "rotate-180"
                              : "rotate-0"
                              }`}
                          />
                        </button>
                      </div>
                      {/* <SummerPrintPreviewModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                      /> */}
                    </div>
                    {/* Accordion Body */}
                    <Accordion.Body
                      className={
                        activeAccordion === status ? "block" : "hidden"
                      }
                    >
                      <ClassRosterTable
                        students={students}
                        classrooms={classrooms?.classrooms || []}
                        handleUpdateStudent={handleUpdateStudent}
                        handleGetClassroom={handleGetClassroom}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* </Accordion> */}
                </div>
              ))}
            {/* <Accordion> */}
            {handleGetClassroom(selectedClassroom)?.summerProgram && studentsOfClassroom?.data?.students?.length > 0 &&
              <Accordion.Item eventKey={"summer_roster_details"}>
                <div className="w-full bg-primaryVariant rounded-lg mb-2 mt-8 px-8 py-3 flex justify-between items-center">
                  <h3 className="text-xl text-secondary font-semibold flex items-center gap-2 uppercase w-full">
                    {"Summer Roster Details"} <GoInfo className="w-5 h-5 opacity-50" />
                  </h3>
                  <div className="flex items-center gap-2">
                    <button onClick={openModal}>
                      <TfiPrinter className="w-6 h-6 text-secondary" />
                    </button>
                    <button onClick={() => toggleAccordion("summer_roster_details")}>
                      <IoChevronDown
                        className={`w-6 h-6 transition-transform ${activeAccordion === "summer_roster_details"
                          ? "rotate-180"
                          : "rotate-0"
                          }`}
                      />
                    </button>
                  </div>

                </div>
                <Accordion.Body
                  className={
                    activeAccordion === "summer_roster_details" ? "block" : "hidden"
                  }
                >
                  <SummerRosterTable
                    students={studentsOfClassroom?.data?.students || []}
                    centerSetup={center?.existingCenterSetup}
                    summerWeeks={summerWeeks}
                    handleUpdateStudent={handleUpdateStudent}
                    handleGetClassroom={handleGetClassroom}
                  />
                </Accordion.Body>
              </Accordion.Item>
            }
          </Accordion>
          <SummerPrintPreviewModal
            isOpen={isModalOpen}
            onClose={closeModal}
            centerSetup={center?.existingCenterSetup}
          />
        </div >
      </DashboardWrapper >
    </>
  );
};

export default ClassRoster;
