import { FC, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import {
  IoIosAddCircleOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { MdInfoOutline } from "react-icons/md";
import { RiDeleteBin3Line } from "react-icons/ri";
import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";
import { IOptionS } from "../../types/ICenterSetup.interface";
import {
  IPartTimeSlots,
  NewClassroom,
} from "../../types/IClassroomSetup.interface";
import { sanitizeInput } from "../../utils/functions/removeLeadingZeros";
import DaysDropdown from "../dropdowns/DaysDropdown";
import InputField from "../inputsFields/InputField";

const tooltips = {
  baProgram: {
    id: "baProgram-tooltip",
    content: `<p>This classroom is in BA program. To assign part-time sessions to this classroom, first remove it from BA program through classroom detailed setup.</p>`,
  },
  ageSequentialClassrooms: {
    id: "ageSequentialClassrooms",
    content: `<p>Here please add all your classrooms that allow children to move directly to the next classroom. In other words, all those classrooms that are age-contiguous with each other. E.g. 0-<span className={"text-[#3396F8]"}>12</span> months, <span className={"text-statusColor"}>13</span>-<span className={"text-dangerAlert"}>23</span> months, <span className={"text-dangerAlert"}>24</span>-<span className={"text-successAlert"}>35</span> months, <span className={"text-successAlert"}>36</span>-47 months, etc.</p>`,
  },
};

interface Props {
  essentialInfo: NewClassroom[];
  partTimeSessions: IPartTimeSlots[];
  editSequentialClasses: boolean;
  setEditSequentialClasses: (check: boolean) => void;
  handleInputChange: (
    name: string,
    value: string | number,
    index: number
  ) => void;
  handleAddPartTimeSessions: (
    slotIds: string[],
    index: number,
    isSequential: boolean
  ) => void;
  handleAddClassroom: (index: number) => void;
  handleRemoveClassroom: (index: number) => void;
  handleSave: () => void;
  errors: string[];
  classroomOptions: IOptionS[];
  handleCancel: () => void;
}

const EssentialInformation: FC<Props> = ({
  essentialInfo,
  partTimeSessions,
  editSequentialClasses,
  setEditSequentialClasses,
  handleInputChange,
  handleAddPartTimeSessions,
  handleAddClassroom,
  handleRemoveClassroom,
  handleSave,
  errors,
  classroomOptions,
  handleCancel,
}) => {
  // const [isEdit, setIsEdit] = useState(false);
  const [partTimeOptions, setPartTimeOptions] = useState<IOptionS[]>([]);

  const handleEdit = () => {
    setEditSequentialClasses(!editSequentialClasses);
  };

  useEffect(() => {
    if (partTimeSessions.length > 0) {
      const partTimeSessionsOptions = partTimeSessions?.map((session) => {
        return {
          label: session.name,
          value: session.slotId || "",
        };
      });
      setPartTimeOptions(partTimeSessionsOptions);
    }
  }, [partTimeSessions]);

  return (
    <>
      <h3 className="text-secondary font-semibold text-xl mb-3 flex items-start gap-2">
        Section 1
      </h3>
      <div className="bg-white p-6 rounded-xl space-y-3 mb-3">
        <div className="flex items-center justify-between gap-3">
          <h2 className="text-xl font-medium mb-3 flex gap-2">
            Age-Sequential Classrooms{" "}
            <span data-tooltip-id={tooltips.ageSequentialClassrooms.id}>
              <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
            </span>
          </h2>
          <TooltipComponent
            id={tooltips.ageSequentialClassrooms.id}
            content={tooltips.ageSequentialClassrooms.content}
            isWhite={true}
          />
          {!editSequentialClasses && (
            <button
              onClick={handleEdit}
              className="text-statusColor inline-flex items-center gap-2 text-lg"
            >
              Modify <FiEdit />
            </button>
          )}
        </div>
        <div className="grid grid-cols-12 gap-4 bg-[#F2F2F2] p-2 rounded-md">
          <div className="col-span-3 grid grid-cols-2 gap-3.5">
            <div>
              <p className="text-base text-secondary font-semibold">
                Classroom <br />{" "}
                <span className="text-base font-semibold "> Name *</span>
              </p>
            </div>
            <div>
              <p className="text-base text-secondary font-semibold">
                <span className="text-base font-semibold whitespace-nowrap">
                  {" "}
                  Part-time <br /> Sessions?
                </span>
              </p>
            </div>
          </div>

          <div className="col-span-7">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-base text-secondary font-semibold border-b border-secondaryVariant2 pb-1">
                  Minimum Age *
                </p>
                <div className="grid grid-cols-4 gap-x-3 text-sm text-secondary font-semibold m-0 pt-1">
                  <div>Years</div>
                  <div>Months</div>
                  <div>Days</div>

                  <div className="text-primary">Days</div>
                </div>
              </div>
              <div>
                <p className="text-base text-secondary font-semibold border-b border-secondaryVariant2 pb-1">
                  Maximum Age *
                </p>
                <div className="grid grid-cols-4 gap-x-3 text-sm text-secondary font-semibold m-0 pt-1">
                  <div>Years</div>
                  <div>Months</div>
                  <div>Days</div>
                  <div className="text-primary">Days</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <p className="text-base text-secondary font-semibold ">
              Students <br /> Transitions To
            </p>
          </div>
        </div>

        {essentialInfo?.map((classroom, index) => {
          const transitionClassroom = classroomOptions.find(
            (option) =>
              option.value === classroom.transition?.TransitionClassroom
          );
          return (
            <div>
              <div className="grid grid-cols-12 mb-4 items-end h-full">
                <div className="grid grid-cols-2 col-span-3">
                  <div className=" bg-[#F2F2F2] p-2 rounded-tl-[4px] rounded-bl-[4px]">
                    <InputField
                      disabled={!editSequentialClasses}
                      label="Name"
                      name="classroomName"
                      type="text"
                      placeholder=""
                      required
                      value={classroom.classroomName}
                      onChange={(e) =>
                        handleInputChange(e.target.name, e.target.value, index)
                      }
                    />
                  </div>
                  <div className="flex gap-2  bg-[#F2F2F2] p-2 h-full ">
                    <div className=" w-full dropDown-essential">
                      <DaysDropdown
                        options={partTimeOptions}
                        label="Select Sessions"
                        placeholder="None"
                        value={classroom?.partTimeSlots?.map(
                          (slots) => slots.slotId as string
                        )}
                        onSelectDays={(slotIds) =>
                          handleAddPartTimeSessions(slotIds, index, true)
                        }
                        // required={true}
                        disabled={
                          !editSequentialClasses ||
                          partTimeSessions?.length === 0 ||
                          classroom?.BAProgram
                        }
                      // onFocus={handleOnFocus}
                      // onBlur={handleOnBlur}
                      />
                      {/* {centerSetupFormErrors?.operationDaysError && <p className="text-red-600 pl-4">{centerSetupFormErrors?.operationDaysError}</p>} */}
                    </div>
                    {/* <InputField
                      disabled={
                        !editSequentialClasses || partTimeSessions?.length === 0
                      }
                      name="hasPartTimeSession"
                      type="checkbox"
                      placeholder=""
                      checked={
                        classroom?.partTimeSlots?.length > 0 &&
                        !classroom?.BAProgram
                      }
                      onChange={(e:any) =>
                        handleAddPartTimeSessions(e.target.checked, index, true)
                      }
                    />
                    <label
                      htmlFor=""
                      className="text-secondaryVariant text-base"
                    >
                      Yes
                    </label> */}
                  </div>
                </div>
                <div className="col-span-7 bg-[#F2F2F2] p-2 h-full flex flex-col justify-end">
                  <div className="grid grid-cols-2 gap-x-4">
                    <div className="grid grid-cols-4 gap-x-3 text-sm text-secondary font-semibold items-center">
                      <div>
                        <InputField
                          disabled={!editSequentialClasses}
                          name="minimumAge.years"
                          type="number"
                          placeholder=""
                          // value={classroom.ageRange.minimumAge.years as number}
                          value={sanitizeInput(
                            classroom.ageRange.minimumAge.years.toString()
                          )}
                          onChange={(e) =>
                            handleInputChange(
                              e.target.name,
                              sanitizeInput(e.target.value),
                              index
                            )
                          }
                        />
                      </div>
                      <div>
                        <InputField
                          disabled={!editSequentialClasses}
                          name="minimumAge.months"
                          type="number"
                          placeholder=""
                          // value={classroom.ageRange.minimumAge.months as number}
                          value={sanitizeInput(
                            classroom.ageRange.minimumAge.months.toString()
                          )}
                          onChange={(e) => {
                            handleInputChange(
                              e.target.name,
                              sanitizeInput(e.target.value),
                              index
                            );
                          }}
                        />{" "}
                      </div>
                      <div>
                        <InputField
                          disabled={!editSequentialClasses}
                          name="minimumAge.days"
                          type="number"
                          placeholder=""
                          value={sanitizeInput(
                            classroom.ageRange.minimumAge.days.toString()
                          )}
                          // value={ classroom.ageRange.minimumAge.days as number}
                          onChange={(e) => {
                            handleInputChange(
                              e.target.name,
                              sanitizeInput(e.target.value),
                              index
                            );
                          }}
                        />{" "}
                      </div>
                      <div className="text-base text-primary flex items-start flex-col gap-2">
                        <span className="font-semibold">
                          {classroom.ageRange.minimumAge.ageInDays || 0}
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <div className="grid grid-cols-4 gap-x-3 text-sm text-secondary font-semibold items-center">
                        <div>
                          <InputField
                            disabled={!editSequentialClasses}
                            name="maxAge.years"
                            type="number"
                            placeholder=""
                            // value={
                            //   classroom.ageRange.maximumAge.years as number
                            // }
                            value={sanitizeInput(
                              classroom.ageRange.maximumAge.years.toString()
                            )}
                            onChange={(e) =>
                              handleInputChange(
                                e.target.name,
                                sanitizeInput(e.target.value),
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <InputField
                            disabled={!editSequentialClasses}
                            name="maxAge.months"
                            type="number"
                            placeholder=""
                            // value={
                            //   classroom.ageRange.maximumAge.months as number
                            // }
                            value={sanitizeInput(
                              classroom.ageRange.maximumAge.months.toString()
                            )}
                            onChange={(e) =>
                              handleInputChange(
                                e.target.name,
                                sanitizeInput(e.target.value),
                                index
                              )
                            }
                          />
                        </div>
                        <div>
                          <InputField
                            disabled={!editSequentialClasses}
                            name="maxAge.days"
                            type="number"
                            placeholder=""
                            // value={classroom.ageRange.maximumAge.days as number}
                            value={sanitizeInput(
                              classroom.ageRange.maximumAge.days.toString()
                            )}
                            onChange={(e) =>
                              handleInputChange(
                                e.target.name,
                                sanitizeInput(e.target.value),
                                index
                              )
                            }
                          />
                        </div>
                        <div className="text-base text-primary flex items-start flex-col gap-2">
                          <span className="font-semibold">
                            {classroom.ageRange.maximumAge.ageInDays || 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-2 h-full flex items-center">
                  <div className="flex gap-4 h-full flex-1 items-center ">
                    <div className="flex-1 flex flex-row bg-[#F2F2F2] p-2 rounded-tr-[4px] rounded-br-[4px] h-full items-end gap-1 justify-between pb-6">
                      {(transitionClassroom?.label ??
                        essentialInfo[index + 1]?.classroomName) ||
                        "None"}
                      <div className="bg-white p-1 rounded-full max-w-8 text-center flex items-center justify-center">
                        <button
                          className={`text-dangerAlert ${!editSequentialClasses ? "opacity-50" : ""
                            }`}
                          onClick={() => {
                            handleRemoveClassroom(index);
                          }}
                          disabled={!editSequentialClasses}
                        >
                          <RiDeleteBin3Line className="w-6 h-6 " />
                        </button>
                      </div>
                    </div>
                    <div className="flex-[0_0_71px]">
                      {editSequentialClasses && (
                        <div className="flex items-center justify-center gap-3 pt-5">
                          {/* {index !== 0 && (
                            <button
                              className="text-base text-dangerAlert flex items-center flex-col"
                              onClick={() => handleRemoveClassroom(index)}
                            >
                              <CiCircleMinus className="w-6 h-6" />
                              Delete
                            </button>
                          )} */}

                          <button
                            className="text-base text-statusColor flex items-center flex-col"
                            onClick={() => handleAddClassroom(index)}
                          >
                            <IoIosAddCircleOutline className="w-6 h-6" />
                            Add
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {editSequentialClasses && (
          <div>
            {errors &&
              errors?.map((error) => {
                return (
                  <div>
                    <p className="text-dangerAlert">{error}</p>
                  </div>
                );
              })}
            {errors && <div />}
            <p className="text-statusColor text-sm mb-4">
              *Save only after you have added ALL classrooms in this section
            </p>
            <button
              onClick={() => {
                handleSave();
                // setIsEdit(false);
              }}
              className={`bg-primary text-white rounded-lg h-[58px] px-6 min-w-[125px] ${errors?.length > 0 ? "opacity-50" : ""
                }`}
              disabled={errors?.length > 0}
            >
              Save
            </button>
            <button
              onClick={() => {
                handleCancel();
                setEditSequentialClasses(false);
              }}
              className=" text-black rounded-lg h-[58px] ml-4 px-6 min-w-[125px] border-secondary border"
            >
              Cancel
            </button>
          </div>
        )}

        {/* <button
          onClick={() => {
            setIsEdit(true);
            handleAddClassroom();
          }}
          className="text-statusColor inline-flex items-center justify-center  rounded-lg border border-[#f2f2f2] h-[58px] px-6 gap-3"
        >
          <CiCirclePlus size={24} />
          Add Age-Sequential Classroom
        </button> */}
      </div>
    </>
  );
};

export default EssentialInformation;
