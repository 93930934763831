import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb, {
  BreadcrumbItem,
} from "../../hr-setup_modules/components/Breadcrumb";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";
import { showErrorToast, showSuccessToast } from "../../../shared/utils/toast";
import TimeDropdown from "../../components/dropdowns/TimeDropdown";
import InputField from "../../components/inputsFields/InputField";
import { timeOptions } from "../../constants/constantValues";
import {
  initClassroomDetailed,
  initClassroomDetailedFormErrors,
  initPartTimeSession,
  initPartTimeSessionsBAProgram,
} from "../../constants/initialState";
import { forayRoutes } from "../../routes/routes";

import { useQuery } from "@tanstack/react-query";
import { queryClient } from "../../../../config/useQuery.config";
import { formatTwoDigitNumber } from "../../../shared/utils/formatNumbers";
import ConfirmationModal from "../../components/modals/BaProgramConfirmationModal";
import { SessionType, Slot } from "../../enums/Foray.enums";
import { classroomSvc } from "../../services/classroom.service";
import { studentSvc } from "../../services/student.service";
import { IOptionS } from "../../types/ICenterSetup.interface";
import {
  IBasicInfoClassDetailed,
  IClassroomDetailed,
  IClassroomSetup,
  IPartTimeSessionsClassDetailed,
  ISelfBreakClassDetailed,
} from "../../types/IClassroomSetup.interface";

const ModifiedClassRoom = () => {
  const [classroomDetailedForm, setClassroomDetailedForm] =
    useState<IClassroomDetailed>(initClassroomDetailed);
  const [isFirstInfo, setIsFirstInfo] = useState(false);
  const [classroomDetailedFormErrors, setClassroomDetailedFormErrors] =
    useState(initClassroomDetailedFormErrors);
  const [classroom, setClassroom] = useState<IClassroomSetup>();
  const [isDisabled, setIsDisabled] = useState(false);
  // const [classroomOptions, setClassroomOptions] = useState<IOptionS[]>([
  //   { value: Slot.FULL_TIME, label: Slot.FULL_TIME },
  // ]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const isModify = location.search.includes("modify");
  const [isBAProgram, setIsBAProgram] = useState(false);

  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_dashboard },
    { label: "Classroom-Details", link: forayRoutes.classroom_detailed },
    { label: "Classroom-Details Setup", isCurrent: true },
  ].filter(Boolean) as BreadcrumbItem[];

  const { data: classrooms } = useQuery<IClassroomSetup[]>({
    queryKey: ["classrooms", location.state?.classroomId],
    queryFn: async () => {
      const res = await classroomSvc.fetchClassrooms();
      if (res?.ok) {
        return res.data?.existingClassroomSetups;
      }
      return [];
    },
    staleTime: 0,
    retryOnMount: true,
  });

  useEffect(() => {
    if (!classrooms?.length || !location?.state?.classroomId) return;
    const classroomToModify = classrooms.find((classroom, index) => {
      if (classroom?._id === location.state?.classroomId) {
        if (
          index === 0 &&
          !(
            classroom?.capacity.licenseCapacity &&
            classroom.capacity.physicalCapacity &&
            classroom.capacity.studentPerTeacher
          )
        ) {
          setIsFirstInfo(true);
        } else {
          setIsFirstInfo(false);
        }
        return classroom;
      }
    });
    // setIsBAProgram(classroomToModify?.BAProgram ? true : false);
    setClassroom(classroomToModify);
    console.log("classroom: ", classroomToModify)
    setClassroomDetailedForm((prev: any) => ({
      ...prev,
      basicInfo: {
        ...prev.basicInfo,
        nickName: classroomToModify?.nickName as string,
        licenseCapacity: classroomToModify?.capacity?.licenseCapacity || null,
        physicalCapacity: classroomToModify?.capacity?.physicalCapacity || null,
        stuTeachRatio: classroomToModify?.capacity?.studentPerTeacher || 1,
        classroomSession:
          classroomToModify?.settingType || SessionType.FullTimeOnly,
        schoolAgeBAProgram: classroomToModify?.BAProgram || false,
      },
      partTimeSessions: classroomToModify?.partTimeSlots?.map((session) => ({
        // ...initPartTimeSessionsBAProgram,
        startTime: session?.startTime as string,
        endTime: session?.endTime as string,
        name: session?.name as string,
        transitionTo: session?.transition as string,
        uuid: session?.uuid as string,
      })) || [initPartTimeSessionsBAProgram],
      selfBreak: {
        ...prev?.selfBreak,
        allowed: classroomToModify?.selfBreaking as boolean,
        startTime: classroomToModify?.selfBreakingStartTime as string,
        endTime: classroomToModify?.selfBreakingEndTime as string,
      },
      ageRange: {
        ...prev?.ageRange,
        minimumAge: classroomToModify?.ageRange.minimumAge,
        maximumAge: classroomToModify?.ageRange.maximumAge,
      },
    }));
  }, [classrooms, location?.state?.classroomId]);
  // console.log("check: ", classroom);

  const tooltips = {
    classrooms: {
      id: "classrooms-tooltip",
      content: `<p>Please enter the total number of classrooms here.</p>`,
    },
    selfBreak: {
      id: "selfBreak-tooltip",
      content:
        "<p>If this classroom allows increased student teacher ratio during nap time, and teachers can break themselves, then please select 'Yes' and indicate the total time allowed for breaks for all teachers in <strong>THIS</strong> classroom.</p>",
    },
    minMaxAgeLimit: {
      id: "minMaxAgeLimit",
      content:
        "For now we strongly recommend you leave both at 0 until you're more familiar with Foray.",
    },
    summerProgram: {
      id: "summer-program-tooltip",
      content: "Start Date should be a Monday",
    },
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    const [topKey, subKey, indexKey] = name.split("."); // Handles nested properties and array indices
    setClassroomDetailedForm((prev) => {
      const updatedForm = { ...prev };

      // Handle updates for partTimeSessions (array case)
      // if (topKey === "partTimeSessions" && indexKey !== undefined) {
      //   const index = parseInt(indexKey, 10);

      //   if (!Array.isArray(updatedForm.partTimeSessions)) {
      //     console.error("Expected partTimeSessions to be an array");
      //     return prev; // Early return in case of type mismatch
      //   }

      //   const updatedArray = [...updatedForm.partTimeSessions];
      //   updatedArray[index] = {
      //     ...updatedArray[index],
      //     [subKey as keyof IPartTimeSessionsClassDetailed]: value,
      //   };
      //   updatedForm.partTimeSessions = updatedArray;
      // }
      // Handle updates for basicInfo
      // else 
      if (topKey === "basicInfo") {
        updatedForm.basicInfo = {
          ...updatedForm.basicInfo,
          [subKey as keyof IBasicInfoClassDetailed]:
            type === "checkbox" ? checked : value,
        };
      }
      // Handle updates for selfBreak
      else if (topKey === "selfBreak") {
        updatedForm.selfBreak = {
          ...updatedForm.selfBreak,
          [subKey as keyof ISelfBreakClassDetailed]:
            type === "checkbox" ? checked : value,
        };
      } else {
        console.error(`Unhandled topKey: ${topKey}`);
      }

      return updatedForm;
    });
  };

  // const handleSession = (session: string) => {
  //   setClassroomDetailedForm((prev) => ({
  //     ...prev,
  //     basicInfo: { ...prev.basicInfo, classroomSession: session },
  //     partTimeSessions:
  //       session === SessionType.FullAndPartTime
  //         ? (classroom?.partTimeSlots?.length &&
  //           classroom?.partTimeSlots?.map((slot) => ({
  //             ...initPartTimeSession,
  //             startTime: slot?.startTime || "",
  //             endTime: slot?.endTime || "",
  //             name: slot?.name || "",
  //             transitionTo: slot?.transition || "",
  //           }))) || [initPartTimeSession]
  //         : [],
  //   }));
  // };

  const handleSelfBreak = (check: boolean) => {
    setClassroomDetailedForm((prev) => ({
      ...prev,
      selfBreak: { ...prev.selfBreak, allowed: check },
    }));

    if (check === false) {
      setClassroomDetailedForm((prev) => ({
        ...prev,
        selfBreak: {
          allowed: false,
          startTime: "",
          endTime: "",
        },
      }));
    }
  };

  // const handleAddPartTimeSession = (index: number) => {
  //   setClassroomDetailedForm((prev) => {
  //     const updatedSessions = [...prev.partTimeSessions];
  //     updatedSessions.splice(index + 1, 0, initPartTimeSession); // Add the new session after the specified index

  //     return {
  //       ...prev,
  //       partTimeSessions: updatedSessions,
  //     };
  //   });
  // };
  // const handleRemovePartTimeSession = (index: number) => {
  //   setClassroomDetailedForm((prev) => ({
  //     ...prev,
  //     partTimeSessions: prev.partTimeSessions.filter((_, i) => i !== index),
  //   }));
  // };

  // const handleShoolAgeBAProgram = (checked: boolean) => {
  //   if (checked) {
  //     setClassroomDetailedForm((prev) => ({
  //       ...prev,
  //       partTimeSessions: initPartTimeSessionsBAProgram,
  //     }));
  //   } else
  //     setClassroomDetailedForm((prev) => ({
  //       ...prev,
  //       partTimeSessions: [initPartTimeSession],
  //     }));
  //   setClassroomDetailedForm((prev) => ({
  //     ...prev,
  //     basicInfo: {
  //       ...prev.basicInfo,
  //       schoolAgeBAProgram: !prev.basicInfo.schoolAgeBAProgram,
  //     },
  //   }));
  // };

  const handleTimeSelect = (value: string, name: string) => {
    const [topKey, subKey, index] = name.split(".");
    setClassroomDetailedForm((prev) => {
      const temp = { ...prev };
      // if (index) {
      //   const updatedArray = [...temp.partTimeSessions];
      //   updatedArray[+index] = {
      //     ...updatedArray[+index],
      //     [subKey]: value,
      //   };
      //   temp.partTimeSessions = updatedArray;
      // } else {
      temp.selfBreak = {
        ...temp.selfBreak,
        [subKey]: value,
      };
      // }
      return temp;
    });
  };

  const handleGetNameOfTransitionClassroom = () => {
    const transitionClass = classrooms?.find(
      (item) => item._id === classroom?.transition?.TransitionClassroom
    );
    const nameParts = transitionClass?.classroomName.split(" ");
    return { first: nameParts?.[0], second: nameParts?.slice(1).join("") };
  };

  // const handleTransitionTo = (index: number, value: string) => {
  //   setClassroomDetailedForm((prev) => ({
  //     ...prev,
  //     partTimeSessions: prev.partTimeSessions.map((session, i) =>
  //       i === index ? { ...session, transition: value } : session
  //     ),
  //   }));
  // };

  const isEndTimeAfterStartTime = (startTime: string, endTime: string) => {
    if (startTime === "" || endTime === "") {
      return true;
    }
    const format = "hh:mm A";
    const start = moment(startTime, format);
    const end = moment(endTime, format);
    return end.isAfter(start);
  };

  const renderCapacityWarning = () => {
    const { physicalCapacity, licenseCapacity } =
      classroomDetailedForm?.basicInfo || {};

    const shouldShowWarning =
      physicalCapacity &&
      licenseCapacity &&
      Number(physicalCapacity) < Number(licenseCapacity);

    if (!shouldShowWarning) return null;

    return (
      <p className="text-red-600 pl-3">
        {`Physical capacity can not be less than license capacity`}
      </p>
    );
  };

  console.log("check form: ", classroomDetailedForm);

  const validateForm = () => {
    const errors: any = {
      basicInfoError: {},
      selfBreakError: {},
      partTimeSessionsError: {},
    };
    if (!classroomDetailedForm?.basicInfo?.licenseCapacity) {
      errors.basicInfoError.licenseCapacityError =
        "License capacity is required";
    }
    if (!classroomDetailedForm?.basicInfo?.physicalCapacity) {
      errors.basicInfoError.physicalCapacityError =
        "Physical capacity is required";
    }

    if (!classroomDetailedForm?.basicInfo?.stuTeachRatio) {
      errors.basicInfoError.stuTeachRatioError =
        "Student to teacher ratio is required";
    }
    // if (
    //   classroomDetailedForm?.basicInfo?.classroomSession ===
    //   SessionType.FullAndPartTime &&
    //   isBAProgram
    // ) {
    //   classroomDetailedForm?.partTimeSessions?.forEach((session, index) => {
    //     if (index !== 2) {
    //       if (!session.name) {
    //         errors.partTimeSessionsError[index].nameError =
    //           "Name of part time session is required";
    //       }
    //       if (!session.startTime) {
    //         errors.partTimeSessionsError[index].startTimeError =
    //           "Start of part time session time is required";
    //       }
    //       if (!session.endTime) {
    //         errors.partTimeSessionsError[index].endTimeError =
    //           "End time of part time session is required";
    //       }
    //       if (!isEndTimeAfterStartTime(session.startTime, session.endTime)) {
    //         errors.partTimeSessionsError[index].endTimeError =
    //           "End time must be after start time";
    //       }
    //       if (!session.transition) {
    //         errors.partTimeSessionsError[index].transitionToError =
    //           "Transition to of part time session is required";
    //       }
    //     }
    //   });
    // }
    if (classroomDetailedForm?.selfBreak?.allowed) {
      if (!classroomDetailedForm.selfBreak?.startTime) {
        errors.selfBreakError.startTimeError = "Start time is required";
      }
      if (!classroomDetailedForm.selfBreak?.endTime) {
        errors.selfBreakError.endTimeError = "End time is required";
      }
      if (
        !isEndTimeAfterStartTime(
          classroomDetailedForm.selfBreak?.startTime,
          classroomDetailedForm.selfBreak?.endTime
        )
      ) {
        errors.selfBreakError.endTimeError =
          "End time must be after start time";
      }
    }

    setClassroomDetailedFormErrors(errors);
    return (
      Object.keys(errors.basicInfoError).length === 0 &&
      Object.keys(errors.selfBreakError).length === 0
    );
  };
  const hasRequiredProperties = () => {
    const isBasicInfoComplete =
      // !!classroomDetailedForm?.basicInfo?.nickName &&
      !!classroomDetailedForm?.basicInfo?.licenseCapacity && classroomDetailedForm?.basicInfo?.licenseCapacity > 0 &&
      !!classroomDetailedForm?.basicInfo?.physicalCapacity && classroomDetailedForm?.basicInfo?.physicalCapacity > 0 &&
      !!classroomDetailedForm?.basicInfo?.stuTeachRatio &&
      Number(classroomDetailedForm?.basicInfo?.physicalCapacity) >=
      Number(classroomDetailedForm?.basicInfo?.licenseCapacity);

    // const arePartTimeSessionsComplete =
    //   classroomDetailedForm?.basicInfo?.classroomSession ===
    //     SessionType.FullAndPartTime && isBAProgram
    //     ? classroomDetailedForm.partTimeSessions
    //       .filter((session) => session.name !== "before after school") // Filter out before after school
    //       .every(
    //         (session) =>
    //           !!session.name &&
    //           !!session.startTime &&
    //           !!session.endTime &&
    //           !!session.transition
    //       )
    //     : true;

    const isSelfBreakComplete = classroomDetailedForm?.selfBreak?.allowed
      ? !!classroomDetailedForm?.selfBreak?.startTime &&
      !!classroomDetailedForm?.selfBreak?.endTime
      : true;

    setIsDisabled(
      !(
        isBasicInfoComplete &&
        // arePartTimeSessionsComplete &&
        isSelfBreakComplete
      )
    );
  };

  useEffect(() => {
    hasRequiredProperties();
    validateForm();
  }, [classroomDetailedForm]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (!validateForm()) {
      showErrorToast("Please fill out all required fields");
      return; // Stop form submission if validation fails
    }

    let reqBody: any = {
      settingType: classroomDetailedForm?.basicInfo?.classroomSession,
      // BAProgram: classroomDetailedForm?.basicInfo?.schoolAgeBAProgram,
      nickName: classroomDetailedForm?.basicInfo?.nickName,
      capacity: {
        licenseCapacity:
          Number(classroomDetailedForm?.basicInfo?.licenseCapacity) || 0,
        physicalCapacity:
          Number(classroomDetailedForm?.basicInfo?.physicalCapacity) || 0,
        studentPerTeacher:
          Number(classroomDetailedForm?.basicInfo?.stuTeachRatio) || 0,
      },
      selfBreaking: classroomDetailedForm?.selfBreak?.allowed,
      ...(classroomDetailedForm?.selfBreak.allowed && {
        selfBreakingStartTime: classroomDetailedForm?.selfBreak?.startTime,
        selfBreakingEndTime: classroomDetailedForm?.selfBreak?.endTime,
      }),
      ageRange: {
        minimumAge: classroomDetailedForm?.ageRange.minimumAge,
        maximumAge: classroomDetailedForm?.ageRange.maximumAge,
      },
    };
    reqBody.ageRange.maximumAge = classroomDetailedForm?.ageRange?.maximumAge;
    reqBody.ageRange.minimumAge = classroomDetailedForm?.ageRange?.minimumAge;
    if (classroomDetailedForm?.partTimeSessions?.length > 0) {
      reqBody.partTimeSlots = classroomDetailedForm?.partTimeSessions?.map(
        (session) => ({
          name: session.name,
          startTime: session.startTime || undefined,
          endTime: session.endTime || undefined,
          transition: session.transition,
          uuid: session.uuid ?? "",
          // ...(session?.uuid && { uuid: session.uuid || "" }),
        }))
    }
    // if (
    //   classroomDetailedForm?.basicInfo?.classroomSession ===
    //   SessionType.FullAndPartTime
    // )

    //  {
    // const beforeSchoolSession = classroomDetailedForm?.partTimeSessions?.find(
    //   (session) => session.name === "before school"
    // );
    // const afterSchoolSession = classroomDetailedForm?.partTimeSessions?.find(
    //   (session) => session.name === "after school"
    // );

    // reqBody.partTimeSlots = classroomDetailedForm?.partTimeSessions?.map(
    //   (session) => {
    //     if (session.name === "before/after school") {
    //       return {
    //         name: session.name,
    //         startTime: beforeSchoolSession?.startTime || undefined,
    //         endTime: afterSchoolSession?.endTime || undefined,
    //         transition: session.transition,
    //         uuid: session.uuid ?? "",
    //         // ...(session?.uuid && { uuid: session.uuid || "" }),
    //       };
    //     }
    //     return {
    //       name: session.name,
    //       startTime: session.startTime || undefined,
    //       endTime: session.endTime || undefined,
    //       transition: session.transition,
    //       uuid: session.uuid ?? "",
    //       // ...(session?.uuid && { uuid: session.uuid || "" }),
    //     };
    //   }
    // );
    // } else if (
    //   classroomDetailedForm?.basicInfo?.classroomSession ===
    //   SessionType.FullTimeOnly
    // ) {
    //   reqBody.partTimeSlots = [];
    // }


    const res = await classroomSvc.updateClassroomSetup(
      classroom?._id as string,
      reqBody
    );
    if (res?.ok) {
      showSuccessToast("Classroom modified successfully");
      queryClient.invalidateQueries({ queryKey: ["classrooms"] });
      navigate(forayRoutes.classroom_detailed);
    } else if (
      res?.error.statusCode === 400 &&
      res?.error?.message?.includes(
        "Cannot remove part-time slots as there are students assigned to the following"
      )
    ) {
      showErrorToast(res?.error?.message);
      setIsConfirmationModalOpen(true);
    }
  };

  const handleProceed = async () => {
    if (classroom?._id) {
      const resp = await studentSvc.moveStudentsToFullTime(classroom._id);
      const e = {
        preventDefault: () => { },
      } as React.FormEvent<HTMLFormElement>;

      handleSubmit(e);
    } else {
      showErrorToast("Can not find classroom id");
    }
  };

  return (
    <DashboardWrapper>
      <div className="px-6 py-5 bg-secondaryNeutral overflow-y-scroll h-[calc(100vh-85px)] custom-scrollbar">
        <Breadcrumb items={breadcrumbItems} />

        {/* <div className="container py-5 md:py-8 lg:py-10 xl:py-28"> */}
        <h2 className="text-2xl text-secondary font-semibold mb-6">
          Classroom-Details
        </h2>
        <p className="text-base font-normal text-secondary max-w-[711px] mb-9">
          {isModify
            ? "Let's modify the classroom."
            : isFirstInfo
              ? "Let's start with the first classroom."
              : "Let's move to the next classroom."}{" "}
          First, let's review Classroom-Essentials.
        </p>
        <form action="" onSubmit={handleSubmit}>
          <div className="bg-[#DADADA] rounded-xl p-6 mb-3">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-5">
                <h3 className="text-secondary text-xl font-semibold">
                  {classroom?.classroomName}
                </h3>
                <hr className="text-white my-4" />
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <h4 className="text-secondary text-base font-semibold mb-3">
                      Minimum Age
                    </h4>
                    <div className="grid grid-cols-4 gap-3">
                      <div>
                        <p className="text-secondaryVariant">Years</p>
                        <p className="text-secondary">
                          {formatTwoDigitNumber(
                            classroom?.ageRange?.minimumAge?.years as number
                          ) || 0}{" "}
                        </p>
                      </div>
                      <div>
                        <p className="text-secondaryVariant">Month</p>
                        <p className="text-secondary">
                          {formatTwoDigitNumber(
                            classroom?.ageRange?.minimumAge?.months as number
                          ) || 0}{" "}
                        </p>
                      </div>
                      <div>
                        <p className="text-secondaryVariant">Days</p>
                        <p className="text-secondary">
                          {formatTwoDigitNumber(
                            classroom?.ageRange?.minimumAge?.days as number
                          ) || 0}{" "}
                        </p>
                      </div>
                      {/* <div>
                        <p className="text-secondaryVariant">Age in Days</p>
                        <p className="text-secondary">
                          {formatTwoDigitNumber(
                            classroom?.ageRange?.minimumAge?.ageInDays as number
                          ) || 0}
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div>
                    <h4 className="text-secondary text-base font-semibold mb-3">
                      Maximum Age
                    </h4>
                    <div className="grid grid-cols-4 gap-3">
                      <div>
                        <p className="text-secondaryVariant">Years</p>
                        <p className="text-secondary">
                          {formatTwoDigitNumber(
                            classroom?.ageRange?.maximumAge?.years as number
                          ) || 0}{" "}
                        </p>
                      </div>
                      <div>
                        <p className="text-secondaryVariant">Month</p>
                        <p className="text-secondary">
                          {formatTwoDigitNumber(
                            classroom?.ageRange?.maximumAge?.months as number
                          ) || 0}{" "}
                        </p>
                      </div>
                      <div>
                        <p className="text-secondaryVariant">Days</p>
                        <p className="text-secondary">
                          {formatTwoDigitNumber(
                            classroom?.ageRange?.maximumAge?.days as number
                          ) || 0}{" "}
                        </p>
                      </div>
                      {/* <div>
                        <p className="text-secondaryVariant">Age in Days</p>
                        <p className="text-secondary">
                          {formatTwoDigitNumber(
                            classroom?.ageRange?.maximumAge?.ageInDays as number
                          ) || 0}
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-3">
                <h3 className="text-secondary text-xl font-semibold">
                  Session
                </h3>

                <hr className="text-white my-4" />
                <h4 className="text-secondary text-base font-semibold mb-3">
                  Classroom has which students?
                </h4>
                <div className="flex items-center gap-3">
                  <div className="flex flex-col gap-3 text-secondary text-base font-medium">
                    {classroomDetailedForm?.basicInfo?.classroomSession ===
                      SessionType.FullTimeOnly
                      ? "Full Time only"
                      : "Full And Part Time"}
                    {/* <div className="flex items-center ">
                      <input
                        id={`slot-radio-1`}
                        type="radio"
                        name="basicInfo.classroomSession"
                        className="appearance-none w-0 h-0 opacity-0 invisible peer bg-white"
                        checked={
                          classroomDetailedForm?.basicInfo?.classroomSession ===
                          SessionType.FullTimeOnly
                        }
                        onChange={handleInputChange}
                        disabled
                      />
                      <label
                        htmlFor={`slot-radio-1`}
                        className={`relative text-secondaryVariant flex items-center gap-3 before:content-[''] before:w-5 before:h-5 before:border before:bg-white before:border-secondaryVariant2/50 before:inline-block before:rounded-full text-sm font-medium peer-checked:before:border-primary  peer-checked:before:bg-primary peer-checked:before:shadow-[inset_0_0_0_2px_#fff]`}
                      >
                        Full Time Only
                      </label>
                    </div>

                    <div className="flex items-center">
                      <input
                        id={`slot-radio-2`}
                        type="radio"
                        name="basicInfo.classroomSession"
                        className="appearance-none w-0 h-0 opacity-0 invisible peer bg-white"
                        checked={
                          classroomDetailedForm?.basicInfo?.classroomSession ===
                          SessionType.FullAndPartTime
                        }
                        onChange={handleInputChange}
                        disabled
                      />
                      <label
                        htmlFor={`slot-radio-2`}
                        className={`relative text-secondaryVariant flex items-center gap-3 before:content-[''] before:w-5 before:h-5 before:border before:bg-white before:border-secondaryVariant2/50 before:inline-block before:rounded-full text-sm font-medium peer-checked:before:border-primary  peer-checked:before:bg-primary peer-checked:before:shadow-[inset_0_0_0_2px_#fff]`}
                      >
                        Full & Part Time
                      </label>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-span-4">
                <h2 className="text-xl text-secondary font-semibold">
                  Auto Transition
                </h2>
                <hr className="text-white my-4" />

                <div className="grid grid-cols-5 gap-3">
                  <div className="col-span-2">
                    <h4 className="text-secondary text-base font-semibold mb-3">
                      Classroom
                    </h4>
                    <p className="text-secondary font-medium">
                      {handleGetNameOfTransitionClassroom().first || ""}
                    </p>
                    <p className="text-secondary font-medium">
                      {handleGetNameOfTransitionClassroom().second ||
                        (handleGetNameOfTransitionClassroom().first
                          ? ""
                          : "None")}
                    </p>
                  </div>
                  <div className="col-span-3">
                    <h4 className="text-secondary text-base font-semibold mb-3">
                      Age at Transition
                    </h4>
                    <div className="grid grid-cols-3 gap-3">
                      <div>
                        <p className="text-secondaryVariant">Years</p>
                        <p className="text-secondary">
                          {formatTwoDigitNumber(
                            classroom?.transition?.years as number
                          )}{" "}
                        </p>
                      </div>
                      <div>
                        <p className="text-secondaryVariant">Month</p>
                        <p className="text-secondary">
                          {formatTwoDigitNumber(
                            classroom?.transition?.months as number
                          )}{" "}
                        </p>
                      </div>
                      <div>
                        <p className="text-secondaryVariant">Days</p>
                        <p className="text-secondary">
                          {formatTwoDigitNumber(
                            classroom?.transition?.days as number
                          )}{" "}
                        </p>
                      </div>
                      {/* <div>
                        <p className="text-secondaryVariant">Age in Days</p>
                        <p className="text-secondary">
                          {formatTwoDigitNumber(
                            classroom?.transition?.ageInDays as number
                          )}
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <hr className="my-4 border border-[#f2f2f2]" /> */}
          </div>
          <div className="bg-white rounded-xl p-6 mb-3">
            <h2 className="text-xl text-secondary font-semibold mb-6 flex items-start">
              Now, Let’s Add Classroom-Details Information{" "}
              {/* <span data-tooltip-id={tooltips.DaysHoursOfOperation.id}>
                <IoMdInformationCircleOutline className="w-5 h-5 text-dangerAlert" />
              </span> */}
            </h2>
            {/* <TooltipComponent
              id={tooltips.DaysHoursOfOperation.id}
              content={tooltips.DaysHoursOfOperation.content}
              isWhite={true}
            /> */}

            <div className="grid grid-cols-6 gap-3 mb-6">
              <div className="w-full">
                <InputField
                  label="Nick Name"
                  type="text"
                  name="basicInfo.nickName"
                  placeholder="Enter nick name"
                  value={classroomDetailedForm?.basicInfo?.nickName}
                  onChange={handleInputChange}
                />
              </div>

              <div className="w-full">
                <InputField
                  label="Physical Capacity"
                  type="number"
                  name="basicInfo.physicalCapacity"
                  placeholder="Enter physical capacity"
                  required
                  value={
                    (classroomDetailedForm?.basicInfo
                      ?.physicalCapacity as number) ?? 0
                  }
                  onChange={handleInputChange}
                />
                {renderCapacityWarning()}
                {/* {classroomDetailedForm?.basicInfo.physicalCapacity &&
                  classroomDetailedForm?.basicInfo.licenseCapacity &&
                  classroomDetailedForm?.basicInfo.physicalCapacity <
                    classroomDetailedForm?.basicInfo.licenseCapacity && (
                    <p className="text-red-600 pl-3">
                      {`Physical capacity ${classroomDetailedForm?.basicInfo.physicalCapacity} can not be less than license capacity ${classroomDetailedForm?.basicInfo.licenseCapacity} `}
                    </p>
                  )} */}
              </div>

              <div className="w-full">
                <InputField
                  label="License Capacity"
                  type="number"
                  name="basicInfo.licenseCapacity"
                  placeholder=""
                  required
                  value={
                    (classroomDetailedForm?.basicInfo
                      ?.licenseCapacity as number) ?? 0
                  }
                  onChange={handleInputChange}
                />
              </div>

              <div className="w-full">
                <InputField
                  min={1}
                  label="Stu-Teach Ratio"
                  type="number"
                  name="basicInfo.stuTeachRatio"
                  placeholder=""
                  required
                  value={
                    (classroomDetailedForm?.basicInfo
                      ?.stuTeachRatio as number) ?? 1
                  }
                  onChange={handleInputChange}
                />
              </div>
              {/* <div className="w-full col-span-2">
                <label
                  htmlFor=""
                  className="text-base text-secondaryVariant mb-4 block"
                >
                  Classroom Session
                </label>
                <div className="flex flex-wrap gap-4">
                  <div className="flex items-center">
                    <input
                      id={`slot-radio-1`}
                      type="radio"
                      name="classroomSession-fullPartTime"
                      className="appearance-none w-0 h-0 opacity-0 invisible peer bg-white"
                      checked={
                        classroomDetailedForm?.basicInfo?.classroomSession ===
                        SessionType.FullAndPartTime
                      }
                      onChange={() => {
                        handleSession(SessionType.FullAndPartTime);
                      }}
                    />
                    <label
                      htmlFor={`slot-radio-1`}
                      className={`relative cursor-pointer text-secondaryVariant flex items-center gap-3 before:content-[''] before:w-5 before:h-5 before:border before:bg-white before:border-secondaryVariant2/50 before:inline-block before:rounded-full text-sm font-medium peer-checked:before:border-primary  peer-checked:before:bg-primary peer-checked:before:shadow-[inset_0_0_0_2px_#fff]`}
                    >
                      Full/Part Time
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      id={`slot-radio-2`}
                      type="radio"
                      name="classroomSession-fullTime"
                      className="appearance-none w-0 h-0 opacity-0 invisible peer bg-white"
                      checked={
                        classroomDetailedForm?.basicInfo?.classroomSession ===
                        SessionType.FullTimeOnly
                      }
                      onChange={() => {
                        handleSession(SessionType.FullTimeOnly);
                      }}
                    />
                    <label
                      htmlFor={`slot-radio-2`}
                      className={`relative cursor-pointer text-secondaryVariant flex items-center gap-3 before:content-[''] before:w-5 before:h-5 before:border before:bg-white before:border-secondaryVariant2/50 before:inline-block before:rounded-full text-sm font-medium peer-checked:before:border-primary  peer-checked:before:bg-primary peer-checked:before:shadow-[inset_0_0_0_2px_#fff]`}
                    >
                      Full Time
                    </label>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="flex justify-between items-end">
              <div>
                <div className="flex items-start">
                  {" "}
                  <label
                    htmlFor=""
                    className="text-base text-secondaryVariant mb-2"
                  >
                    Number of children allowed by regulations above and below
                    age range{" "}
                  </label>
                  <span data-tooltip-id={tooltips.minMaxAgeLimit.id}>
                    <IoMdInformationCircleOutline className="w-5 h-5 text-dangerAlert" />
                  </span>
                  <TooltipComponent
                    id={tooltips.minMaxAgeLimit.id}
                    content={tooltips.minMaxAgeLimit.content}
                    isWhite={true}
                  />
                </div>

                <div className="py-1 px-4 rounded-lg bg-secondaryNeutral flex items-center gap-6">
                  <div className="flex items-center gap-3">
                    <label
                      htmlFor=""
                      className="text-base text-secondaryVariant font-medium"
                    >
                      Below Minimum age{" "}
                    </label>{" "}
                    <input
                      min={0}
                      type="number"
                      className="border-secondaryVariant2 rounded-lg ps-3 p-0.5 w-16 h-12 bg-white"
                      value={
                        classroomDetailedForm?.ageRange?.minimumAge
                          ?.countAllowedOutsideRange ?? 0
                      }
                      onChange={(e) =>
                        setClassroomDetailedForm((prev: any) => ({
                          ...prev,
                          ageRange: {
                            ...prev.ageRange,
                            minimumAge: {
                              ...prev.ageRange.minimumAge,
                              countAllowedOutsideRange:
                                parseInt(e.target.value) || 0,
                            },
                          },
                        }))
                      }
                    />
                  </div>
                  <div className="flex items-center gap-3">
                    <label
                      htmlFor=""
                      className="text-base text-secondaryVariant font-medium"
                    >
                      Above Maximum age{" "}
                    </label>{" "}
                    <input
                      min={0}
                      value={
                        classroomDetailedForm?.ageRange?.maximumAge
                          ?.countAllowedOutsideRange ?? 0
                      }
                      onChange={(e) =>
                        setClassroomDetailedForm((prev: any) => ({
                          ...prev,
                          ageRange: {
                            ...prev.ageRange,
                            maximumAge: {
                              ...prev.ageRange.maximumAge,
                              countAllowedOutsideRange:
                                parseInt(e.target.value) || 0,
                            },
                          },
                        }))
                      }
                      type="number"
                      className="border-secondaryVariant2 rounded-lg ps-3 p-0.5 w-16 h-12 bg-white"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="flex items-center gap-3 pb-4">
                <input
                  // onChange={(e) => {
                  //   if (
                  //     classroomDetailedForm?.basicInfo?.classroomSession ===
                  //     SessionType.FullAndPartTime
                  //   ) {
                  //     setIsBAProgram(e.target.checked);
                  //     setClassroomDetailedForm((prev) => ({
                  //       ...prev,
                  //       partTimeSessions: e.target.checked
                  //         ? [...initPartTimeSessionsBAProgram].map(
                  //             (session) => ({
                  //               ...session,
                  //             })
                  //           )
                  //         : [],
                  //       basicInfo: {
                  //         ...prev.basicInfo,
                  //         schoolAgeBAProgram: e.target.checked,
                  //       },
                  //     }));
                  //   } else {
                  //     showInfoToast(toastMessage.Part_Time_Only_BA_Program);
                  //   }
                  // }}
                  onChange={(e) => {
                    setIsBAProgram(e.target.checked);

                    setClassroomDetailedForm((prev) => ({
                      ...prev,
                      partTimeSessions: e.target.checked
                        ? [...initPartTimeSessionsBAProgram].map((session) => ({
                            ...session,
                          }))
                        : [],
                      basicInfo: {
                        ...prev.basicInfo,
                        schoolAgeBAProgram: e.target.checked,
                        classroomSession: e.target.checked
                          ? SessionType.FullAndPartTime
                          : SessionType.FullTimeOnly,
                      },
                    }));
                  }}
                  type="checkbox"
                  checked={isBAProgram}
                  className="appearance-none h-6 w-6 border-2 border-gray-500 rounded cursor-pointer 
                             checked:bg-white checked:border-primary 
                             checked:before:content-['✔']
                              checked:before:text-primary 
                             checked:before:block checked:before:text-center"
                />

                <span className="text-base text-secondaryVariant font-medium">
                  Is this classroom School Aged/Before/After Program?
                </span>
              </div> */}
            </div>
            {/* {classroomDetailedForm?.basicInfo?.classroomSession ===
                SessionType.FullAndPartTime && (
                <div>
                  <CustomCheckbox
                    text="School Aged B/A Program"
                    checked={classroomDetailedForm?.basicInfo?.schoolAgeBAProgram}
                    onChange={handleShoolAgeBAProgram}
                  />
                </div>
              )} */}

            <h2 className={`text-xl text-secondary font-semibold my-11 flex items-start ${classroom?.BAProgram || classroom?.summerProgram ? "opacity-50" : ""}`}>
              Self-Breaks
              <span data-tooltip-id={tooltips.selfBreak.id}>
                <IoMdInformationCircleOutline className="w-5 h-5 text-dangerAlert" />
              </span>
            </h2>
            <TooltipComponent
              id={tooltips.selfBreak.id}
              content={tooltips.selfBreak.content}
              isWhite={true}
            />
            <div className={`grid grid-cols-6 gap-6 bottomDays ${classroom?.BAProgram || classroom?.summerProgram ? "opacity-50" : ""}`}>
              <div className="w-full ">
                <label
                  htmlFor=""
                  className="text-base text-secondaryVariant mb-4 block"
                >
                  Self-Break Allow
                </label>
                <div className="flex flex-wrap justify-between gap-4">
                  <div className="flex items-center">
                    <input
                      id={`slot-radio-3`}
                      type="radio"
                      name="selfBreak.allowed"
                      className="appearance-none w-0 h-0 opacity-0 invisible peer bg-white"
                      checked={classroomDetailedForm?.selfBreak?.allowed}
                      // onChange={handleInputChange}
                      onChange={() => {
                        handleSelfBreak(true);
                      }}
                      disabled={classroom?.BAProgram || classroom?.summerProgram}
                    />
                    <label
                      htmlFor={`slot-radio-3`}
                      className={`relative ${classroom?.BAProgram || classroom?.summerProgram ? "" : "cursor-pointer"} text-secondaryVariant flex items-center gap-3 before:content-[''] before:w-5 before:h-5 before:border before:bg-white before:border-secondaryVariant2/50 before:inline-block before:rounded-full text-sm font-medium peer-checked:before:border-primary  peer-checked:before:bg-primary peer-checked:before:shadow-[inset_0_0_0_2px_#fff]`}
                    >
                      Yes
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      id={`slot-radio-4`}
                      type="radio"
                      name="selfBreak.allowed"
                      className="appearance-none w-0 h-0 opacity-0 invisible peer bg-white"
                      checked={!classroomDetailedForm?.selfBreak?.allowed}
                      onChange={() => {
                        handleSelfBreak(false);
                      }}
                      disabled={classroom?.BAProgram || classroom?.summerProgram}
                    />
                    <label
                      htmlFor={`slot-radio-4`}
                      className={`relative ${classroom?.BAProgram || classroom?.summerProgram ? "" : "cursor-pointer"} text-secondaryVariant flex items-center gap-3 before:content-[''] before:w-5 before:h-5 before:border before:bg-white before:border-secondaryVariant2/50 before:inline-block before:rounded-full text-sm font-medium peer-checked:before:border-primary  peer-checked:before:bg-primary peer-checked:before:shadow-[inset_0_0_0_2px_#fff]`}
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className=" w-full">
                <TimeDropdown
                  key={classroomDetailedForm?.selfBreak?.startTime}
                  times={timeOptions}
                  label="Start Break Time"
                  placeholder="Set time"
                  value={classroomDetailedForm?.selfBreak?.startTime}
                  onSelectTime={(value) => {
                    handleTimeSelect(value, "selfBreak.startTime");
                  }}
                  required={classroomDetailedForm?.selfBreak?.allowed}
                  disabled={!classroomDetailedForm?.selfBreak?.allowed || classroom?.BAProgram || classroom?.summerProgram}
                />
              </div>
              <div className=" w-full">
                <TimeDropdown
                  key={classroomDetailedForm?.selfBreak?.endTime}
                  times={timeOptions}
                  label="End Break Time"
                  placeholder="Set time"
                  value={classroomDetailedForm?.selfBreak?.endTime}
                  onSelectTime={(value) => {
                    handleTimeSelect(value, "selfBreak.endTime");
                  }}
                  required={classroomDetailedForm?.selfBreak?.allowed}
                  disabled={!classroomDetailedForm?.selfBreak?.allowed || classroom?.BAProgram || classroom?.summerProgram}
                />
                {classroomDetailedFormErrors?.selfBreakError?.endTimeError &&
                  classroomDetailedForm?.selfBreak?.endTime && (
                    <p className="text-red-600 pl-3">
                      {
                        classroomDetailedFormErrors?.selfBreakError
                          ?.endTimeError
                      }
                    </p>
                  )}
              </div>

              <div></div>
            </div>
          </div>
          {/* {isBAProgram && (
            <div className="bg-white rounded-xl p-6 mb-3">
              <h2 className="text-xl text-secondary font-semibold mb-0 flex items-start">
                School Aged/Before/After Sessions{" "}
                <span data-tooltip-id={tooltips.DaysHoursOfOperation.id}>
                  <IoMdInformationCircleOutline className="w-5 h-5 text-dangerAlert" />
                </span>
              </h2>
              <TooltipComponent
                id={tooltips.DaysHoursOfOperation.id}
                content={tooltips.DaysHoursOfOperation.content}
                isWhite={true}
              />
              ////This was commented

              <p className="text-primary text-sm mb-6">
                You may <strong>ADD</strong> up to 4 part-time sessions per
                classroom.{" "}
              </p>  {classroomDetailedForm?.partTimeSessions?.map(
                (session, index) => (
                  <>
                    <div className="grid grid-cols-6 gap-3 items-end mb-6">
                      <div className="w-full">
                        <InputField
                          label={`Name`}
                          type="text"
                          name={`partTimeSessions.name.${index}`}
                          placeholder={`Before Program`}
                          value={session.name}
                          onChange={handleInputChange}
                          required
                          disabled={
                            classroomDetailedForm?.basicInfo?.schoolAgeBAProgram
                          }
                        />
                      </div>

                      <div className=" w-full">
                        <TimeDropdown
                          key={session.startTime}
                          times={timeOptions}
                          label="Start Time"
                          placeholder="Set start time"
                          value={session.startTime}
                          onSelectTime={(value) => {
                            handleTimeSelect(
                              value,
                              `partTimeSessions.startTime.${index}`
                            );
                          }}
                          required
                        />
                      </div>
                      <div className=" w-full">
                        <TimeDropdown
                          key={session.endTime}
                          times={timeOptions}
                          label="End Time"
                          placeholder="Set end time"
                          value={session.endTime}
                          onSelectTime={(value) => {
                            handleTimeSelect(
                              value,
                              `partTimeSessions.endTime.${index}`
                            );
                          }}
                          required
                        />
                      </div>
                      {!classroomDetailedForm?.basicInfo
                        ?.schoolAgeBAProgram && (
                          <div className=" w-full">
                            <label
                              htmlFor=""
                              className="text-base text-secondaryVariant mb-1 block"
                            >
                              Transition To
                              <RequiredAsterisk />
                            </label>
                            <Select
                              key={session.transitionTo}
                              className="text-start w-full"
                              placeholder="Select"
                              isClearable
                              options={classroomOptions}
                              value={classroomOptions?.find(
                                (opt) => opt.value === session.transitionTo
                              )}
                              onChange={(selectedOption) => {
                                handleTransitionTo(
                                  index,
                                  selectedOption?.value as string
                                );
                              }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "grey"
                                    : "#CED2DA",
                                  padding: "8px 0",
                                  borderRadius: "8px",
                                }),
                              }}
                            />
                          </div>
                        )}
                    </div>
                    <div className="grid grid-cols-6 gap-3 items-end mb-6">
                      <div className="w-full">
                        <InputField
                          label={`Name`}
                          type="text"
                          name={`partTimeSessions.name.${index}`}
                          placeholder={`After Program`}
                          value={session.name}
                          onChange={handleInputChange}
                          required
                          disabled={
                            classroomDetailedForm?.basicInfo?.schoolAgeBAProgram
                          }
                        />
                      </div>

                      <div className=" w-full">
                        <TimeDropdown
                          key={session.startTime}
                          times={timeOptions}
                          label="Start Time"
                          placeholder="Set start time"
                          value={session.startTime}
                          onSelectTime={(value) => {
                            handleTimeSelect(
                              value,
                              `partTimeSessions.startTime.${index}`
                            );
                          }}
                          required
                        />
                      </div>
                      <div className=" w-full">
                        <TimeDropdown
                          key={session.endTime}
                          times={timeOptions}
                          label="End Time"
                          placeholder="Set end time"
                          value={session.endTime}
                          onSelectTime={(value) => {
                            handleTimeSelect(
                              value,
                              `partTimeSessions.endTime.${index}`
                            );
                          }}
                          required
                        />
                      </div>
                      {!classroomDetailedForm?.basicInfo
                        ?.schoolAgeBAProgram && (
                          <div className=" w-full">
                            <label
                              htmlFor=""
                              className="text-base text-secondaryVariant mb-1 block"
                            >
                              Transition To
                              <RequiredAsterisk />
                            </label>
                            <Select
                              key={session.transitionTo}
                              className="text-start w-full"
                              placeholder="Select"
                              isClearable
                              options={classroomOptions}
                              value={classroomOptions?.find(
                                (opt) => opt.value === session.transitionTo
                              )}
                              onChange={(selectedOption) => {
                                handleTransitionTo(
                                  index,
                                  selectedOption?.value as string
                                );
                              }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "grey"
                                    : "#CED2DA",
                                  padding: "8px 0",
                                  borderRadius: "8px",
                                }),
                              }}
                            />
                          </div>
                        )}
                    </div>
                    <div className="grid grid-cols-6 gap-3 items-center mb-6">
                      <div className="w-full">
                        <InputField
                          label={`Name`}
                          type="text"
                          name={`partTimeSessions.name.${index}`}
                          placeholder={`Before/After`}
                          value={session.name}
                          onChange={handleInputChange}
                          required
                          disabled={
                            classroomDetailedForm?.basicInfo?.schoolAgeBAProgram
                          }
                        />
                      </div>

                      <div className=" w-full col-span-2 text-dangerAlert text-xs">
                        Sessions will be a combination of the above timings
                      </div>
                      {!classroomDetailedForm?.basicInfo
                        ?.schoolAgeBAProgram && (
                          <div className=" w-full">
                            <label
                              htmlFor=""
                              className="text-base text-secondaryVariant mb-1 block"
                            >
                              Transition To
                              <RequiredAsterisk />
                            </label>
                            <Select
                              key={session.transitionTo}
                              className="text-start w-full"
                              placeholder="Select"
                              isClearable
                              options={classroomOptions}
                              value={classroomOptions?.find(
                                (opt) => opt.value === session.transitionTo
                              )}
                              onChange={(selectedOption) => {
                                handleTransitionTo(
                                  index,
                                  selectedOption?.value as string
                                );
                              }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "grey"
                                    : "#CED2DA",
                                  padding: "8px 0",
                                  borderRadius: "8px",
                                }),
                              }}
                            />
                          </div>
                        )}
                    </div>
                  </>
                )
              )}
              ///////commented till here

              {[0, 1, 2].map((index) => {
                // console.log(
                //   "classroomDetailedForm.partTimeSessions[index]",
                //   classroomDetailedForm.partTimeSessions
                // );
                const labels = [
                  "Before Program",
                  "After Program",
                  "Before/After",
                ];
                const showTimings = index !== 2; // Don't show time inputs for Before/After

                return (
                  <div
                    key={index}
                    className="grid grid-cols-6 gap-3 items-end mb-6"
                  >
                    <div className="w-full">
                      <InputField
                        label={`Name`}
                        type="text"
                        name={`${initPartTimeSessionsBAProgram[index].name}`}
                        placeholder={labels[index]}
                        value={
                          classroomDetailedForm?.partTimeSessions?.[index]?.name
                        }
                        onChange={handleInputChange}
                        required
                        disabled={isBAProgram}
                      />
                    </div>

                    {showTimings ? (
                      <>
                        <div className="w-full">
                          <TimeDropdown
                            key={
                              initPartTimeSessionsBAProgram[index]?.startTime
                            }
                            times={timeOptions}
                            label="Start Time"
                            placeholder="Set start time"
                            value={
                              classroomDetailedForm?.partTimeSessions?.[index]
                                ?.startTime ??
                              initPartTimeSessionsBAProgram[index]?.startTime
                            }
                            onSelectTime={(value) => {
                              handleTimeSelect(
                                value,
                                `partTimeSessions.startTime.${index}`
                              );
                            }}
                            required
                          />
                        </div>
                        <div className="w-full">
                          <TimeDropdown
                            key={initPartTimeSessionsBAProgram[index]?.endTime}
                            times={timeOptions}
                            label="End Time"
                            placeholder="Set end time"
                            value={
                              classroomDetailedForm?.partTimeSessions?.[index]
                                ?.endTime ??
                              initPartTimeSessionsBAProgram[index]?.endTime
                            }
                            onSelectTime={(value) => {
                              handleTimeSelect(
                                value,
                                `partTimeSessions.endTime.${index}`
                              );
                            }}
                            required
                          />
                        </div>
                      </>
                    ) : (
                      <div className="w-full col-span-2 text-dangerAlert text-xs">
                        Sessions will be a combination of the above timings
                      </div>
                    )}

                    <div className="w-full">
                      <label
                        htmlFor=""
                        className="text-base text-secondaryVariant mb-1 block"
                      >
                        Transition To
                        <RequiredAsterisk />
                      </label>
                      <Select
                        key={initPartTimeSessionsBAProgram[index]?.transition}
                        className="text-start w-full"
                        placeholder="Select"
                        isClearable
                        options={classroomOptions}
                        value={classroomOptions?.find(
                          (opt) =>
                            opt.value ===
                            classroomDetailedForm?.partTimeSessions?.[index]
                              ?.transition
                        )}
                        onChange={(selectedOption) => {
                          handleTransitionTo(
                            index,
                            selectedOption?.value as string
                          );
                        }}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? "grey" : "#CED2DA",
                            padding: "8px 0",
                            borderRadius: "8px",
                          }),
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              ///// This was commented
              <p className="text-red-600 pl-3">
                {isEndTimeAfterStartTime(
                  classroomDetailedForm.operationStartTime,
                  classroomDetailedForm.operationEndTime
                )
                  ? ""
                  : "Start time should be before end time."}
              </p>
             ////commented till here////
            </div>
          )} */}

          <div className="flex items-center gap-3">
            <button
              className={`btnPrimary max-w-[310px] ${isDisabled ? "opacity-50" : ""
                }`}
              type="submit"
              disabled={isDisabled}
            >
              {!isModify ? "Save & Next Classroom-Details" : "Save"}
            </button>
            {isModify && (
              <button
                className="btnSimple  max-w-[267px]"
                type="button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Come Back Later
              </button>
            )}
          </div>
        </form>
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          onProceed={handleProceed}
          message="This action cannot be undone. All selected students will be withdrawn from the program."
          actionText="You have unchecked BA program but there are still students in those sessions. Do you want to transfer all students to full time?"
        />
      </div>
    </DashboardWrapper>
  );
};

export default ModifiedClassRoom;
