import { useQuery } from "@tanstack/react-query";
import { StudentQueryKeyString } from "../enums/studentQueryKey.enum";
import { studentSvc } from "../services/student.service";
import { Filters } from "../types/IStudentSetup.interface";

export const useStudents = (filters?: Filters) => {
  return useQuery({
    queryKey: [StudentQueryKeyString.Students, filters],
    queryFn: () => studentSvc.fetchStudents(filters),
    staleTime: 1000 * 30,
    refetchOnMount:true,
    refetchOnWindowFocus:true
  });
};

export const useStudentsList = (fetchAgain: boolean, filters: Filters) => {
  return useQuery({
    queryKey: [StudentQueryKeyString.StudentList, filters,fetchAgain],
    queryFn: () => studentSvc.fetchStudents(filters),
    staleTime: 1000 * 30,
    refetchOnMount: true,
    refetchOnWindowFocus: true
  });
};


export const useStudentsOfClass = (fetchAgain: boolean, classroomId: string, filters?: Filters) => {
  return useQuery({
    queryKey: [StudentQueryKeyString.StudentsOfClass, classroomId, filters, fetchAgain],
    queryFn: () => studentSvc.fetchClassroomStudents(classroomId, filters),
    staleTime: 1000 * 30,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    enabled: !!classroomId
  });
};
