import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { Nav } from "react-bootstrap";
import { FaRegSquarePlus } from "react-icons/fa6";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineTrash } from "react-icons/hi";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import Breadcrumb from "../../hr-setup_modules/components/Breadcrumb";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import useOutsideClick from "../../../shared/hooks/useOutsideClick";
import AddCategoryModal from "../../components/modals/AddCategoryModal";
import AddSectionModal from "../../components/modals/AddSectionModal";
import FillTaskInformation from "../../components/taskGenerationComponents/FillTaskInformation";
import TaskGenCard from "../../components/taskGenerationComponents/TaskGenCard";
import TaskGenerationListing from "../../components/taskGenerationComponents/TaskGenerationListing";
import { useCategories } from "../../hooks/useCateogories";
import { useDeleteSection } from "../../hooks/useDeleteSection";
import { useGetTasks } from "../../hooks/useGetTasks";
import { useUpdateTasks } from "../../hooks/useUpdateTasks";
import { forayRoutes } from "../../routes/routes";
import { classroomSvc } from "../../services/classroom.service";
import { foraySvc } from "../../services/foray.service";
import { ICategory, Section } from "../../types/ICategories.interface";
import { ICenterSetupRes } from "../../types/ICenterSetup.interface";
import { IClassroomSetup } from "../../types/IClassroomSetup.interface";
import { ITasks } from "../../types/ITaskGeneration.interface";

const TaskGeneration = () => {
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedCard, setSelectedCard] = useState<ICategory>();
  const [selectedSection, setSelectedSection] = useState<Section>();
  const [showSectionOptions, setShowSectionOptions] = useState(false);

  const sectionOptionsRef = useRef<HTMLDivElement>(null);
  useOutsideClick(sectionOptionsRef, () => {
    setShowSectionOptions(false);
  });

  const { data: tasks, refetch: refetchTasks } = useGetTasks(
    selectedCard?._id as string,
    selectedSection?._id as string
  );
  const { data: Categories } = useCategories();
  const { mutate: deleteSection, isSuccess: isDeleteSectionSuccess } =
    useDeleteSection();

  const { mutate: updateTasks } = useUpdateTasks();

  const { data: centerSetup } = useQuery<ICenterSetupRes>({
    queryKey: ["get-center-setup"],
    queryFn: async () => {
      const res = await foraySvc.fetchCenterSetup();

      if (res?.ok) {
        return res?.data;
      }
      return;
    },
  });

  const { data: classroomSetups } = useQuery<IClassroomSetup[]>({
    queryKey: ["get-classroom-setups"],
    queryFn: async () => {
      const res = await classroomSvc.fetchClassrooms();
      if (res?.ok) {
        return res?.data?.existingClassroomSetups;
      }
      return [];
    },
  });

  const handleDeleteSection = (categoryId: string, sectionId: string) => {
    if (categoryId && sectionId) {
      deleteSection({ categoryId, sectionId });
    } else {
      // console.log("Category id or section id is not available");
    }
  };
  const openCategoryModal = (modalType: string) => {
    setModalType(modalType);
    setIsCategoryModalOpen(true);
  };

  const closeCategoryModal = useCallback(() => {
    setSelectedCard(undefined);
    setModalType("");
    setIsCategoryModalOpen(false);
  }, []);

  const openSectionModal = (modalType: string) => {
    setModalType(modalType);
    setIsSectionModalOpen(true);
  };

  const closeSectionModal = () => {
    setIsSectionModalOpen(false);
    setModalType("");
  };

  const handleToggleDBView = (item: any, value: boolean) => {
    const updatedItem = {
      ...item,
      isVisibleOnDashboard: value,
    };
    updateTasks({ reqBody: { tasks: [updatedItem] } });
  };

  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_dashboard },
    { label: "Task Generation", isCurrent: true },
  ];

  const handleSelectedCard = (selectedCardId: string) => {
    const selectedCategory = Categories?.data?.find(
      (category) => category._id === selectedCardId
    );
    setSelectedCard(selectedCategory);

    // When a different category is selected, automatically select its first section
    if (selectedCategory?.sections && selectedCategory.sections.length > 0) {
      setSelectedSection(selectedCategory.sections[0]);
    } else {
      setSelectedSection(undefined);
    }
  };

  const handleSelectedSection = (section: Section | undefined) => {
    setSelectedSection(section);
  };

  useEffect(() => {
    if (Categories?.ok && Categories?.data && Categories?.data?.length > 0) {
      // If no category is selected yet or if the selected category no longer exists, select the first one
      if (
        !selectedCard ||
        !Categories.data.find((category) => category._id === selectedCard._id)
      ) {
        const firstCategory = Categories.data[0];
        setSelectedCard(firstCategory);

        // If the first category has sections, select the first section
        if (firstCategory.sections && firstCategory.sections.length > 0) {
          setSelectedSection(firstCategory.sections[0]);
        }
      } else {
        // If a category is already selected, update it with fresh data
        const updatedCategory = Categories.data.find(
          (category) => category._id === selectedCard._id
        );
        setSelectedCard(updatedCategory);

        // If a section is selected, update it or select the first section if it no longer exists
        if (updatedCategory?.sections && updatedCategory.sections.length > 0) {
          if (
            selectedSection &&
            updatedCategory.sections.find(
              (section) => section._id === selectedSection._id
            )
          ) {
            setSelectedSection(
              updatedCategory.sections.find(
                (section) => section._id === selectedSection._id
              )
            );
          } else {
            setSelectedSection(updatedCategory.sections[0]);
          }
        }
      }
    }
  }, [Categories]);

  useEffect(() => {
    if (isDeleteSectionSuccess) {
      setSelectedSection(undefined);
    }
  }, [isDeleteSectionSuccess]);
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-6 overflow-y-scroll h-[calc(100vh-84px)] custom-scrollbar">
          <Breadcrumb items={breadcrumbItems} />
          <div className="flex items-center justify-between mb-5 mt-10">
            <h2 className="text-xl text-secondary font-semibold">
              Task Generation
            </h2>
            <button
              className="btnPrimary max-w-[192px] gap-2"
              onClick={() => {
                openCategoryModal("add");
              }}
            >
              <FaRegSquarePlus className="w-5 h-5" /> Add Category
            </button>
            <AddCategoryModal
              isOpen={isCategoryModalOpen}
              onClose={closeCategoryModal}
              modalType={modalType}
              selectedCard={selectedCard as ICategory}
            />
          </div>
          <TaskGenCard
            items={Categories?.data}
            handleSelected={handleSelectedCard}
            openModal={openCategoryModal}
            selectedCard={selectedCard as ICategory}
            handleSelectedSection={handleSelectedSection}
          />
          <div className="flex items-end gap-2 my-6">
            <Nav
              activeKey="/0"
              variant="pills"
              onSelect={(selectedKey) => console.log(`selected ${selectedKey}`)}
              className="flex items-center w-full flex-wrap"
            >
              {Categories?.data &&
                Categories.data
                  .find((item) => item._id === selectedCard?._id)
                  ?.sections.map((section) => (
                    <div
                      onClick={() => handleSelectedSection(section)}
                      key={section._id}
                      className=""
                    >
                      <div
                        className={`inline-flex whitespace-nowrap items-center px-3 pb-2 relative ${
                          selectedSection?._id === section._id
                            ? "text-primary text-xl font-bold border-primary"
                            : "text-lg text-secondaryVariant border-secondaryVariant2"
                        } border-b-[2px]  gap-1 hover:border-primary hover:text-primary`}
                      >
                        <span className="cursor-pointer">{section.title}</span>
                        <button
                          onClick={() => {
                            setShowSectionOptions(true);
                          }}
                        >
                          <PiDotsThreeVerticalBold className="w-6 h-6" />
                        </button>
                        {showSectionOptions &&
                          selectedSection?._id === section._id && (
                            <div
                              ref={sectionOptionsRef}
                              className="bg-white p-2 top-[100%_!important] rounded-lg shadow-lg min-w-[137px] left-auto right-0 flex flex-col gap-1 absolute"
                            >
                              <button
                                className="hover:text-primary py-1 px-3 flex items-center gap-3 text-secondaryVariant text-sm font-medium"
                                onClick={() => openSectionModal("edit")}
                              >
                                <FiEdit3 className="text-md" />
                                Edit
                              </button>
                              <button
                                className="hover:text-primary py-1 px-3 flex items-center gap-3 text-secondaryVariant text-sm font-medium"
                                onClick={() =>
                                  handleDeleteSection(
                                    selectedCard?._id as string,
                                    selectedSection?._id as string
                                  )
                                }
                              >
                                <HiOutlineTrash className="text-md" />
                                Delete
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
            </Nav>
            <button
              disabled={!selectedCard}
              className={`
              bg-primary 
              p-1.5 
              rounded 
              text-white
              ${!selectedCard ? "opacity-50 cursor-not-allowed" : ""}
            `}
              onClick={() => openSectionModal("add")}
            >
              <HiOutlinePlusSmall className="w-7 h-7" />
            </button>
          </div>
          <FillTaskInformation
            selectedCard={selectedCard as ICategory}
            selectedSection={selectedSection as Section}
          />
          <TaskGenerationListing
            tasks={tasks?.data as ITasks[]}
            selectedCard={selectedCard as ICategory}
            selectedSection={selectedSection as Section}
            centerSetup={centerSetup as ICenterSetupRes}
            classroomSetups={classroomSetups as IClassroomSetup[]}
            handleToggleDBView={handleToggleDBView}
          />
          <AddSectionModal
            isOpen={isSectionModalOpen}
            onClose={closeSectionModal}
            modalType={modalType}
            selectedCard={selectedCard as ICategory}
            selectedSection={selectedSection as Section}
          />
        </div>
      </DashboardWrapper>
    </>
  );
};

export default TaskGeneration;
