import React, { useState, useEffect } from "react";
import { PiCalendarPlus } from "react-icons/pi";
import ReactSelect from "../../../components/reactSelect/ReactSelect";
import ReactDatePicker from "../../../../shared/components/reactDatePicker/ReactDatePicker";
import { postFiles } from "../../../../shared/services/auth.service";
import {
  formatDateSlashFullYear,
  revertToDateType,
} from "../../../../shared/utils/formatDate";
import { showErrorToast, showSuccessToast } from "../../../../shared/utils/toast";
import { staffMessage } from "../../constants/toastMessages";
import { useCreateStaffMutation } from "../../hooks/mutations/useCreateStaffMutation";
import { useUpdateStaffMutation } from "../../hooks/mutations/useUpdateStaffMutaion";
import { IStaffCreate, PersonalInformation } from "../../types/IStaff";
import RequiredAsterisk from "../../../../shared/components/ui/RequiredAsterisk";
import { Country, State, City } from "country-state-city";
import { EditType } from "../../enums/editType";
import Input, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import "react-phone-number-input/style.css";

interface SelectOption {
  value: string;
  label: string;
}

interface EmployeeInformationFormProps {
  employeeData?: any;
  updateEmployeeData: (data: any) => void;
  navigateToTab: (tabKey: string) => void;
}

const EmployeeInformationForm: React.FC<EmployeeInformationFormProps> = ({
  employeeData,
  updateEmployeeData,
  navigateToTab,
}) => {
  const { mutate: createStaff } = useCreateStaffMutation();
  const { mutate: updateStaff } = useUpdateStaffMutation();
  const isEditMode = !!employeeData?._id;

  const [isSaving, setIsSaving] = useState(false);
  const [employeeInfo, setEmployeeInfo] = useState<IStaffCreate | null>();
  const [initialEmployeeInfo, setInitialEmployeeInfo] =
    useState<IStaffCreate | null>();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [initialImagePreview, setInitialImagePreview] = useState<string | null>(
    null
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  
  // Track if form has been modified
  const [isFormModified, setIsFormModified] = useState(false);

  const [countries, setCountries] = useState<SelectOption[]>([]);
  const [states, setStates] = useState<SelectOption[]>([]);
  const [cities, setCities] = useState<SelectOption[]>([]);

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedState, setSelectedState] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);

  const [selectedCountryOption, setSelectedCountryOption] =
    useState<SelectOption | null>(null);
  const [selectedStateOption, setSelectedStateOption] =
    useState<SelectOption | null>(null);
  const [selectedCityOption, setSelectedCityOption] =
    useState<SelectOption | null>(null);

  // Initialize form with existing employee data if it exists
  useEffect(() => {
    if (employeeData) {
      const initialData = {
        ...employeeData,
        personalInformation: {
          ...employeeData.personalInformation,
        },
      };

      setEmployeeInfo(initialData);
      setInitialEmployeeInfo(JSON.parse(JSON.stringify(initialData))); // Deep copy for reset

      if (employeeData.personalInformation?.profilePictureUrl) {
        setImagePreview(employeeData.personalInformation.profilePictureUrl);
        setInitialImagePreview(
          employeeData.personalInformation.profilePictureUrl
        );
      }

      // Set country, state, city if available
      if (employeeData.personalInformation?.citizenShip) {
        const country = Country.getAllCountries().find(
          (c) => c.name === employeeData.personalInformation.citizenShip
        );
        if (country) {
          setSelectedCountry(country.isoCode);
          setSelectedCountryOption({
            value: country.isoCode,
            label: country.name,
          });

          // Load states for the selected country
          if (employeeData.personalInformation?.state) {
            const stateObj = State.getStatesOfCountry(country.isoCode).find(
              (s) => s.name === employeeData.personalInformation.state
            );
            if (stateObj) {
              setSelectedState(stateObj.isoCode);
              setSelectedStateOption({
                value: stateObj.isoCode,
                label: stateObj.name,
              });

              // Load cities for the selected state
              if (employeeData.personalInformation?.city) {
                const cityObj = City.getCitiesOfState(
                  country.isoCode,
                  stateObj.isoCode
                ).find((c) => c.name === employeeData.personalInformation.city);
                if (cityObj) {
                  setSelectedCity(cityObj.name);
                  setSelectedCityOption({
                    value: cityObj.name,
                    label: cityObj.name,
                  });
                }
              }
            }
          }
        }
      }
    } else {
      // Initialize with empty employee info
      const emptyData = {
        personalInformation: {
          firstName: "",
          lastName: "",
          middleName: "",
          preferredName: "",
          email: "",
          mobileNumber: "",
          dateOfBirth: "",
          gender: "",
          ssn: "",
          currentAddress: "",
          citizenShip: "",
          state: "",
          city: "",
          postalCode: "",
          profilePictureUrl: "",
        },
      };

      setEmployeeInfo(emptyData);
      setInitialEmployeeInfo(JSON.parse(JSON.stringify(emptyData))); // Deep copy for reset
    }
    
    // Reset form modification status
    setIsFormModified(false);
  }, [employeeData]);

  // Check if form has been modified whenever form fields change
  useEffect(() => {
    if (!isEditMode || !employeeInfo || !initialEmployeeInfo) return;
    
    const isModified = () => {
      // Check if profile image has changed
      if (selectedFile || imagePreview !== initialImagePreview) {
        return true;
      }
      
      // Compare personal information fields
      const personalInfo = employeeInfo.personalInformation;
      const initialInfo = initialEmployeeInfo.personalInformation;
      
      if (!personalInfo || !initialInfo) return false;
      
      // Compare all fields in personal information
      return Object.keys(personalInfo).some(key => {
        const k = key as keyof typeof personalInfo;
        return personalInfo[k] !== initialInfo[k];
      });
    };
    
    setIsFormModified(isModified());
  }, [
    employeeInfo,
    initialEmployeeInfo,
    isEditMode,
    selectedFile,
    imagePreview,
    initialImagePreview,
    selectedCountryOption,
    selectedStateOption,
    selectedCityOption
  ]);

  useEffect(() => {
    const allCountries = Country.getAllCountries().map((country) => ({
      value: country.isoCode,
      label: country.name,
    }));
    setCountries(allCountries);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const countryStates = State.getStatesOfCountry(selectedCountry).map(
        (state) => ({
          value: state.isoCode,
          label: state.name,
        })
      );
      setStates(countryStates);

      if (!selectedStateOption) {
        setSelectedState(null);
        setSelectedCity(null);
        setSelectedCityOption(null);
        setCities([]);

        setEmployeeInfo((prev: any) =>
          prev
            ? {
              ...prev,
              personalInformation: {
                ...prev.personalInformation,
                state: "",
                city: "",
              },
            }
            : undefined
        );
      }
    } else {
      setStates([]);
      setCities([]);
    }
  }, [selectedCountry, selectedStateOption]);

  // Update cities when state changes
  useEffect(() => {
    if (selectedCountry && selectedState) {
      const stateCities = City.getCitiesOfState(
        selectedCountry,
        selectedState
      ).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(stateCities);

      if (!selectedCityOption) {
        setSelectedCity(null);
        setEmployeeInfo((prev: any) =>
          prev
            ? {
              ...prev,
              personalInformation: {
                ...prev.personalInformation,
                city: "",
              },
            }
            : undefined
        );
      }
    } else {
      setCities([]);
    }
  }, [selectedCountry, selectedState, selectedCityOption]);

  const validateEmployeeInfo = (info: IStaffCreate | undefined): string[] => {
    const errors: string[] = [];

    if (!info?.personalInformation) {
      errors.push("Please fill out the form");
      return errors;
    }

    const { firstName, lastName, email, mobileNumber } =
      info.personalInformation;

    // First Name validation
    if (!firstName?.trim()) {
      errors.push("First name is required");
    } else if (firstName.length < 2) {
      errors.push("First name must be at least 2 characters long");
    } else if (firstName.length > 50) {
      errors.push("First name must not exceed 50 characters");
    }

    // Last Name validation
    if (!lastName?.trim()) {
      errors.push("Last name is required");
    } else if (lastName.length < 2) {
      errors.push("Last name must be at least 2 characters long");
    } else if (lastName.length > 50) {
      errors.push("Last name must not exceed 50 characters");
    }

    // Email validation
    if (!email?.trim()) {
      errors.push("Email is required");
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        errors.push("Please enter a valid email address");
      }
    }

    if (!mobileNumber?.trim()) {
      errors.push("Mobile number is required");
    } else if (!isPossiblePhoneNumber(mobileNumber)) {
      errors.push("Please enter a valid mobile number");
    }

    return errors;
  };

  const handleSave = async () => {
    // Prevent duplicate submissions
    if (isSaving) {
      console.log("Save already in progress");
      return;
    }
    
    // For edit mode, only save if form is modified
    if (isEditMode && !isFormModified) {
      // Skip saving and navigate directly to next tab
      navigateToTab(EditType.education);
      return;
    }

    // Set saving flag
    setIsSaving(true);

    try {
      if (!employeeInfo) {
        showErrorToast("No employee information to save");
        setIsSaving(false);
        return;
      }

      // Validate form data
      const validationErrors = validateEmployeeInfo(employeeInfo);
      if (validationErrors.length > 0) {
        validationErrors.forEach((error) => showErrorToast(error));
        setIsSaving(false);
        return;
      }

      // Create a copy of employee info to work with
      let updatedEmployeeInfo = { ...employeeInfo };

      // Handle file upload if needed
      if (selectedFile) {
        try {
          const formData = new FormData();
          formData.append("files", selectedFile);

          const uploadResponse: any = await postFiles(formData);
          if (uploadResponse?.data?.urls?.[0]) {
            updatedEmployeeInfo = {
              ...updatedEmployeeInfo,
              personalInformation: {
                ...updatedEmployeeInfo.personalInformation,
                profilePictureUrl: uploadResponse.data.urls[0],
              },
            };
          }
        } catch (uploadError) {
          console.error("Error uploading file:", uploadError);
          showErrorToast(staffMessage.Upload_File_Error);
          // Continue with save even if file upload fails
        }
      }
      
      if (isEditMode) {
        // Update existing employee
        const { _id, ...personalInfoWithoutId } = updatedEmployeeInfo.personalInformation;
        const formattedData = {
          personalInformation: personalInfoWithoutId
        };
        updateStaff(
          {
            staffId: employeeData._id,
            updatedData: formattedData,
          },
          {
            onSuccess: (response) => {
              showSuccessToast("Personal information updated successfully");

              const responseData = response?.data || response;
              updateEmployeeData(responseData);

              setEmployeeInfo(responseData);
              setInitialEmployeeInfo(JSON.parse(JSON.stringify(responseData)));

              if (responseData.personalInformation?.profilePictureUrl) {
                setImagePreview(
                  responseData.personalInformation.profilePictureUrl
                );
                setInitialImagePreview(
                  responseData.personalInformation.profilePictureUrl
                );
              }
              
              // Reset form modification status
              setIsFormModified(false);

              setTimeout(() => {
                navigateToTab(EditType.education);
              }, 100);
            },
            onError: (error) => {
              console.error("Update error:", error);
              showErrorToast("Failed to update employee information");
              setIsSaving(false);
            },
            onSettled: () => {
              setIsSaving(false);
            },
          }
        );
      } else {
        // Create new employee
        createStaff(
          { personInfo: updatedEmployeeInfo },
          {
            onSuccess: (response) => {
              showSuccessToast("Personal information saved successfully");

              // Update parent component with response data
              const responseData = response?.data || response;
              updateEmployeeData(responseData);

              // Update local state with the latest data
              setEmployeeInfo(responseData);
              setInitialEmployeeInfo(JSON.parse(JSON.stringify(responseData)));

              if (responseData.personalInformation?.profilePictureUrl) {
                setImagePreview(
                  responseData.personalInformation.profilePictureUrl
                );
                setInitialImagePreview(
                  responseData.personalInformation.profilePictureUrl
                );
              }
              
              // Reset form modification status
              setIsFormModified(false);

              // Navigate to next tab after a short delay
              setTimeout(() => {
                navigateToTab(EditType.education);
              }, 100);
            },
            onError: (error) => {
              console.error("Save error:", error);
              showErrorToast("Failed to save employee information");
            },
            onSettled: () => {
              // Reset submission flag
              setIsSaving(false);
            },
          }
        );
      }
    } catch (error) {
      console.error("Error in save process:", error);
      showErrorToast("An unexpected error occurred");
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    // Reset form to initial state (either empty form or original employee data)
    if (initialEmployeeInfo) {
      setEmployeeInfo(JSON.parse(JSON.stringify(initialEmployeeInfo)));
    }

    // Reset image to initial state
    setImagePreview(initialImagePreview);
    setSelectedFile(null);

    // Reset location selection to initial state
    if (employeeData && employeeData.personalInformation?.citizenShip) {
      const country = Country.getAllCountries().find(
        (c) => c.name === employeeData.personalInformation.citizenShip
      );
      if (country) {
        setSelectedCountry(country.isoCode);
        setSelectedCountryOption({
          value: country.isoCode,
          label: country.name,
        });

        if (employeeData.personalInformation?.state) {
          const stateObj = State.getStatesOfCountry(country.isoCode).find(
            (s) => s.name === employeeData.personalInformation.state
          );
          if (stateObj) {
            setSelectedState(stateObj.isoCode);
            setSelectedStateOption({
              value: stateObj.isoCode,
              label: stateObj.name,
            });

            if (employeeData.personalInformation?.city) {
              const cityObj = City.getCitiesOfState(
                country.isoCode,
                stateObj.isoCode
              ).find((c) => c.name === employeeData.personalInformation.city);
              if (cityObj) {
                setSelectedCity(cityObj.name);
                setSelectedCityOption({
                  value: cityObj.name,
                  label: cityObj.name,
                });
              }
            }
          }
        }
      }
    } else {
      // Reset to empty state if no employee data
      setSelectedCountry(null);
      setSelectedCountryOption(null);
      setSelectedState(null);
      setSelectedStateOption(null);
      setSelectedCity(null);
      setSelectedCityOption(null);
    }
    
    // Reset form modification status
    setIsFormModified(false);

    showSuccessToast("Form reset to original state");
  };

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];

  // Check for validation errors whenever employee info changes
  useEffect(() => {
    const hasError = validateEmployeeInfo(employeeInfo || undefined);
    setIsDisabled(hasError.length > 0 ? true : false);
  }, [employeeInfo]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 1 * 1024 * 1024) {
        showErrorToast(staffMessage.Invalid_File_Size);
        return;
      }

      if (!file.type.match("image.*")) {
        showErrorToast(staffMessage.Select_Image);
        return;
      }

      setSelectedFile(file);

      const reader = new FileReader();
      reader.onload = (e) => {
        const imagePreview = e.target?.result as string;
        setImagePreview(imagePreview);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="bg-white p-6 rounded-xl">
        <h2 className="text-xl text-secondary font-semibold mb-9">
          Fill Out The Information
        </h2>
        <div className="flex items-center gap-6 px-6 py-3 border border-secondaryNeutral rounded-xl mb-9">
          <div>
            <img
              className="w-[129px] h-[129px] rounded-full border-secondaryVariant2 border-[0.3px]"
              src={`${imagePreview ??
                " https://a360csastorage.blob.core.windows.net/childcare/8669bd41-eb7e-48c2-b750-e7cfdff8cbde-fileName-dummyStudent-img.png"
                }`}
              alt="student"
            />
          </div>
          <div className="text-start max-w-[448px] space-y-6">
            <p className="text-sm text-secondary">
              Personalize with a photo. Employee profile photo will appear on
              your account.
            </p>
            <label className="text-base text-primary border border-primary rounded-lg py-3 px-6 cursor-pointer block max-w-[159px]">
              Change Photo{" "}
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleFileUpload}
              />
            </label>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-3 mb-12">
          <div>
            <label className="text-secondaryVariant text-sm mb-1 block">
              First Name
              <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
              value={employeeInfo?.personalInformation.firstName}
              onChange={(e) =>
                setEmployeeInfo((prev: any) => ({
                  ...prev,
                  personalInformation: {
                    ...prev?.personalInformation,
                    firstName: e.target.value,
                  },
                }))
              }
            />
          </div>
          <div>
            <label className="text-secondaryVariant text-sm mb-1 block">
              Middle Name{" "}
            </label>
            <input
              value={employeeInfo?.personalInformation.middleName}
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
              onChange={(e) =>
                setEmployeeInfo((prev: any) => ({
                  ...prev,
                  personalInformation: {
                    ...prev?.personalInformation,
                    middleName: e.target.value,
                  },
                }))
              }
            />
          </div>
          <div>
            <label className="text-secondaryVariant text-sm mb-1 block">
              Last Name
              <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
              value={employeeInfo?.personalInformation.lastName}
              onChange={(e) =>
                setEmployeeInfo((prev: any) => ({
                  ...prev,
                  personalInformation: {
                    ...prev?.personalInformation,
                    lastName: e.target.value,
                  },
                }))
              }
            />
          </div>
          <div>
            <label className="text-secondaryVariant text-sm mb-1 block">
              Preferred Name{" "}
            </label>
            <input
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
              value={employeeInfo?.personalInformation.preferredName}
              onChange={(e) =>
                setEmployeeInfo((prev: any) => ({
                  ...prev,
                  personalInformation: {
                    ...prev?.personalInformation,
                    preferredName: e.target.value,
                  },
                }))
              }
            />
          </div>
          <div>
            <label className="text-secondaryVariant text-sm mb-1 block">
              Email
              <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
              value={employeeInfo?.personalInformation.email}
              onChange={(e) =>
                setEmployeeInfo((prev: any) => ({
                  ...prev,
                  personalInformation: {
                    ...prev?.personalInformation,
                    email: e.target.value,
                  },
                }))
              }
            />
          </div>
          <div>
            <label className="text-secondaryVariant text-sm mb-1 block">
              Mobile Number
              <RequiredAsterisk />
            </label>
            <Input
              className="border border-secondaryVariant2 rounded-lg py-3.5 ps-3 pe-2 w-full bg-white"
              country="US"
              placeholder="(123) 456-7890"
              value={employeeInfo?.personalInformation?.mobileNumber || ""}
              onChange={(value) =>
                setEmployeeInfo((prev: any) => ({
                  ...prev,
                  personalInformation: {
                    ...prev?.personalInformation,
                    mobileNumber: value,
                  },
                }))
              }
            />
            {employeeInfo?.personalInformation?.mobileNumber &&
              !isPossiblePhoneNumber(
                employeeInfo?.personalInformation?.mobileNumber
              ) && (
                <span className="text-red-600 text-sm mt-1">
                  Invalid phone number
                </span>
              )}
          </div>

          <div>
            <label className="text-secondaryVariant text-sm mb-1 block">
              Date of Birth
            </label>
            <div className="w-full relative">
              <ReactDatePicker
                value={
                  employeeInfo?.personalInformation?.dateOfBirth
                    ? revertToDateType(
                      employeeInfo.personalInformation.dateOfBirth as string
                    )
                    : null
                }
                placeholder="MM/DD/YYYY"
                maxDate={new Date()}
                onChange={(value) =>
                  setEmployeeInfo((prev: any) => ({
                    ...prev,
                    personalInformation: {
                      ...prev?.personalInformation,
                      dateOfBirth: formatDateSlashFullYear(value as Date),
                    },
                  }))
                }
              />
              <PiCalendarPlus className="w-5 h-5 absolute right-3 top-4 pointer-events-none" />
            </div>
          </div>
          <div>
            <ReactSelect
              options={genderOptions}
              label="Gender"
              placeholder="Gender"
              value={
                employeeInfo?.personalInformation.gender
                  ? {
                    value: employeeInfo.personalInformation.gender,
                    label: employeeInfo.personalInformation.gender,
                  }
                  : null
              }
              onChange={(value) =>
                setEmployeeInfo((prev: any) => ({
                  ...prev,
                  personalInformation: {
                    ...prev?.personalInformation,
                    gender: value?.value,
                  },
                }))
              }
            />
          </div>

          <div className="">
            <label className="text-secondaryVariant text-sm mb-1 block truncate line-clamp-1">
              SSN (Social Security Number){" "}
            </label>
            <input
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
              value={employeeInfo?.personalInformation.ssn}
              onChange={(e) => {
                const cleaned = e.target.value.replace(/\D/g, "");

                const trimmed = cleaned.slice(0, 9);

                // Format as XXX-XX-XXXX
                let formatted = trimmed;
                if (trimmed.length > 5) {
                  formatted = `${trimmed.slice(0, 3)}-${trimmed.slice(
                    3,
                    5
                  )}-${trimmed.slice(5)}`;
                } else if (trimmed.length > 3) {
                  formatted = `${trimmed.slice(0, 3)}-${trimmed.slice(3)}`;
                }

                setEmployeeInfo((prev: any) => ({
                  ...prev,
                  personalInformation: {
                    ...prev?.personalInformation,
                    ssn: formatted,
                  },
                }));
              }}
              maxLength={11} // 9 digits + 2 hyphens
              placeholder="XXX-XX-XXXX"
            />
          </div>
          <div>
            <ReactSelect
              label="Citizenship"
              placeholder="Citizenship"
              options={countries}
              value={selectedCountryOption}
              onChange={(option: any) => {
                setSelectedCountryOption(option);

                // Update country code for data lookup
                setSelectedCountry(option?.value || null);

                // Update form data
                setEmployeeInfo((prev: any) => ({
                  ...prev,
                  personalInformation: {
                    ...prev?.personalInformation,
                    citizenShip: option?.label || "",
                    state: "",
                    city: "",
                  },
                }));
              }}
            />
          </div>
          <div>
            <ReactSelect
              label="State"
              placeholder="State"
              options={states}
              value={selectedStateOption}
              onChange={(option: any) => {
                setSelectedStateOption(option);

                setSelectedState(option?.value || null);

                setEmployeeInfo((prev: any) => ({
                  ...prev,
                  personalInformation: {
                    ...prev?.personalInformation,
                    state: option?.label || "",
                    city: "",
                  },
                }));
              }}
              isDisabled={!selectedCountry}
            />
          </div>
          <div>
            <ReactSelect
              label="City"
              placeholder="City"
              options={cities}
              value={selectedCityOption}
              onChange={(option: any) => {
                setSelectedCityOption(option);

                setSelectedCity(option?.value || null);

                setEmployeeInfo((prev: any) => ({
                  ...prev,
                  personalInformation: {
                    ...prev?.personalInformation,
                    city: option?.label || "",
                  },
                }));
              }}
              isDisabled={!selectedState}
            />
          </div>
          <div>
            <label className="text-secondaryVariant text-sm mb-1 block">
              Current Address{" "}
            </label>
            <input
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
              value={employeeInfo?.personalInformation.currentAddress}
              onChange={(e) =>
                setEmployeeInfo((prev: any) => ({
                  ...prev,
                  personalInformation: {
                    ...prev?.personalInformation,
                    currentAddress: e.target.value,
                  },
                }))
              }
            />
          </div>
          <div>
            <label className="text-secondaryVariant text-sm mb-1 block">
              Zip Code{" "}
            </label>
            <input
              value={employeeInfo?.personalInformation.postalCode}
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
              onChange={(e) =>
                setEmployeeInfo((prev: any) => ({
                  ...prev,
                  personalInformation: {
                    ...prev?.personalInformation,
                    postalCode: e.target.value,
                  },
                }))
              }
            />
          </div>
        </div>
        <div className="flex items-center gap-3">
          <button
            onClick={handleSave}
            className={`btnPrimary max-w-[181px] ${isDisabled || isSaving || (isEditMode && !isFormModified)
                ? "opacity-50 cursor-not-allowed"
                : ""
              }`}
            disabled={isDisabled || isSaving || (isEditMode && !isFormModified)}
          >
            {isSaving
              ? "Saving..."
              : isEditMode
                ? "Update & Next"
                : "Save & Next"}
          </button>
          <button
            onClick={handleCancel}
            className={`btnSimple max-w-[181px] ${!isFormModified || isSaving ? "opacity-50 cursor-not-allowed" : ""
              }`}
            disabled={!isFormModified || isSaving}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default EmployeeInformationForm;
